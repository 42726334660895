import React from "react";
import Loader from "../../Loader/Loader";
import { Button, Col, Divider, Row } from "antd";
import { TextField } from "@mui/material";
import moment from "moment";
import { THEME_COLORS } from "../../../config/constants";
import { currencyFormatter } from "../../../helpers/currencyHelper";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import "./OpenInvoiceView.css";

export function OpenInvoiceView({ dataActive }) {
  console.log("invoicedata", dataActive);
  const calculateRemaningAmount = () => {
    const totalRemaning = dataActive.totalAmountDue - dataActive.totalPaid;

    const totalFormater = currencyFormatter(totalRemaning);

    return totalFormater;
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const days = dataActive?.paymentTerms?.days || 0;

  const addedDate = moment(dataActive?.dateService).add(days, "days");

  const onPayDue = (data) => {
    const paymentUrl = data?.invoiceQBOPaymentLink
      ? data.invoiceQBOPaymentLink
      : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    window.open(
      paymentUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    );
    // const paymentUrl = rowData?.invoiceQBOPaymentLink
    //   ? rowData.invoiceQBOPaymentLink
    //   : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    // window.open(
    //   paymentUrl,
    //   "_blank",
    //   "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    // );

    // setIsVisibleModal(true);
    // setModalTitle(`Pay Invoice # ${rowData.invoiceNo}`);
    // setModalContent(
    //   <>
    //     <OpenModalPay />
    //   </>
    // );
  };

  if (dataActive === null) {
    return <Loader />;
  }

  return (
    <>
      <Row gutter={24} style={{ paddingTop: "1rem" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Invoice Number"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.invoiceNo}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Dispatch Number"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.dispatch?.dispatchNo}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            id="standard-basic"
            label="Opportunity"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.opportunity?.opportunityName}
          />
        </Col>
      </Row>

      <Row gutter={24} className="rowInputs">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Account"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.account?.accountName}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Invoice Date"
            variant="outlined"
            fullWidth
            size="small"
            value={moment(dataActive?.invoiceDate).format("MM/DD/YYYY")}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Status"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.invoiceStatus?.name}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Payment Terms"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.paymentTerms?.name}
          />
        </Col>
      </Row>

      <Row gutter={24} className="rowInputs">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Purchase Order"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.dispatch?.PONumber}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Amount"
            variant="outlined"
            fullWidth
            size="small"
            value={currencyFormatter(dataActive?.totalAmountDue)}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Remaining amount"
            variant="outlined"
            fullWidth
            size="small"
            value={calculateRemaningAmount()}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          <TextField
            id="standard-basic"
            label="Payment Method"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.paymentMethod}
          />
        </Col>
      </Row>

      <Row gutter={24} className="rowInputs">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Date Of Service"
            variant="outlined"
            fullWidth
            size="small"
            value={moment(dataActive.invoiceDate).format("MM/DD/YYYY")}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Invoice Paid"
            variant="outlined"
            fullWidth
            size="small"
            value={moment(dataActive.invoiceDate).format("MM/DD/YYYY")}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Total Paid"
            variant="outlined"
            fullWidth
            size="small"
            value={currencyFormatter(dataActive.totalPaid)}
          />
        </Col>
      </Row>

      <Divider
        style={{
          paddingTop: "1rem",
          paddingBottom: "1rem",
          color: THEME_COLORS.NARANJA,
        }}
      >
        Contact Information
      </Divider>

      <Row gutter={24} className="rowInputs">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Contact Name"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.contact?.fullName}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Title"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.contact?.title}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <TextField
            id="standard-basic"
            label="Main Phone"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.contact?.phone?.main}
          />
        </Col>
      </Row>

      <Row gutter={24} className="rowInputs">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <TextField
            id="standard-basic"
            label="Contact Mobile"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.contact?.phone?.mobile}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <TextField
            id="standard-basic"
            label="Email"
            variant="outlined"
            fullWidth
            size="small"
            value={dataActive?.contact?.email?.main}
          />
        </Col>
      </Row>
      {dataActive?.invoiceStatus?._id !== "COMMPAID" &&
        dataActive?.invoiceStatus?._id !== "JCIS" &&
        new Date(addedDate) < today && (
          <>
            <Row gutter={24} className="rowInputs">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                <div className="bannerInterest">
                  <label style={{ fontWeight: "bold", fontSize: "18px" }}>
                    ATTENTION!
                  </label>
                  <span style={{ fontSize: "16px", marginLeft: "5px" }}>
                    This is overdue, adjusted total is now{" "}
                    {currencyFormatter(dataActive.totalAmountDue)} including{" "}
                    {dataActive.penality} interest charge. Click below to pay in
                    full or for assistance, please call 571/449-8010.
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    paddingTop: "1rem",
                    textAlign: "end",
                  }}
                >
                  <Button onClick={() => onPayDue(dataActive)}>
                    <div style={{ display: "flex", fontWeight: "bold" }}>
                      Pay ({currencyFormatter(dataActive.totalAmountDue)})
                      <PaidOutlinedIcon
                        style={{ color: "#008f39", paddingLeft: "10px" }}
                      />
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
    </>
  );
}
