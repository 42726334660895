import { Button, Steps } from "antd";
import React, { useState, useEffect } from "react";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import moment from "moment";
import { OpenProposalView } from "../OpenProposalView/OpenProposalView";
import { OpenDocumentList } from "../../Dashboard";
import { OpenLocationProposal } from "../OpenLocationProposal/OpenLocationProposal";
import { OpenQuotesProposal } from "../OpenQuotesProposal/OpenQuotesProposal";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

export function OpenModalProposal({
  proposal,
  ApproveOpportunity,
  handleRefreshChange,
}) {
  const [documentsArray, setDocumentsArray] = useState([]);
  const [opportunity, setOpportunity] = useState(null);
  const [reload, setReload] = useState(false);

  const getProposalById = async (op) => {
    const { data } = await axios
      .get(`${BACKEND_URL}/opportunities/${op._id}`)
      .catch((err) => console.log(err));

    if (data) {
      setOpportunity(data);
    }
  };

  useEffect(() => {
    getProposalById(proposal);
  }, [reload, proposal]);

  const getDocuments = async () => {
    const url =
      `${BACKEND_URL}/documentmasters/getByOpportunityId/` + proposal._id;

    const opportunityDocuments = await axios
      .get(url)
      .catch((err) => console.log(err));

    const dataElement = [];
    if (opportunityDocuments && opportunityDocuments.data.length > 0) {
      opportunityDocuments.data.forEach((document) => {
        dataElement.push({
          documentName: document.documentName,
          documentType: document.documentType.name,
          uploadedDate: moment(document.created).format("MM/DD/YYYY"),
          documentPath: document.documentPath,
        });
      });
      setDocumentsArray(dataElement);
    }
  };

  useEffect(() => {
    setDocumentsArray([]);
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  const [stepNext, setStepNext] = useState(0);
  return (
    <div style={{ minHeight: "60vh" }}>
      <Steps
        size="small"
        current={stepNext}
        onChange={(v) => setStepNext(v)}
        items={[
          {
            title: "Information",
            icon: <AssignmentLateOutlinedIcon />,
          },
          {
            title: "Quotes",
            icon: <ConstructionOutlinedIcon />,
          },
          {
            title: "Map Location",
            icon: <LocationOnOutlinedIcon />,
          },

          {
            title: "Documents",
            icon: <DifferenceOutlinedIcon />,
          },
        ]}
        style={{ paddingBottom: "1rem" }}
      />

      {stepNext === 0 && <OpenProposalView dataActive={opportunity} />}
      {stepNext === 1 && (
        <OpenQuotesProposal
          dataActive={opportunity}
          reload={reload}
          setReload={setReload}
          handleRefreshChange={handleRefreshChange}
        />
      )}
      {stepNext === 2 && <OpenLocationProposal dataActive={opportunity} />}
      {stepNext === 3 && (
        <OpenDocumentList data={documentsArray} title="Propsal Documents" />
      )}
      {
        (!opportunity?.approvedByCustomer ||
          opportunity.approvedByCustomer === null) &&
          opportunity?.status?._id === "PROPOSAL" &&
          opportunity?.amount > 0 && (
            <div
              style={{
                paddingTop: "2rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => ApproveOpportunity(proposal)}>
                <div style={{ display: "flex", fontWeight: "bold" }}>
                  Approve Proposal
                  <TaskAltOutlinedIcon
                    style={{ color: "#008f39", paddingLeft: "10px" }}
                  />
                </div>
              </Button>
            </div>
          )

        // rowData.approvedByCustomer === true ||
        // rowData.status._id !== "PROPOSAL" ||
        // rowData.ammount <= 0,
      }
    </div>
  );
}
