export const THEME_COLORS = {
  AZUL_OSCURO: "#142850",
  AZUL: "#27496D",
  CELESTE: "#00909E",
  GRIS: "#DAE1E7",
  NARANJA: "#E16726",
};

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const HASH_TOKEN = "hashToken";
export const USER = "user";
export const EMAIL = "email";
export const USERID = "userid";
export const DISPLAYNAME = "displayName";
export const ENTERPRISE = "Trimealo";
export const FLIGHTSCHOOLID = "flightSchoolId";
export const IMAGE = "image";
export const ACCESS = "access";
export const USERNAME = "userName";
export const ACCOUNTS = "accounts";

export const BACKEND_USER = { _id: "00000002", roles: ["user"] };

export const ACTION_BUTTONS = {
  EDIT: "edit",
  EMAIL: "email",
  WHATSAPP: "whatsApp",
  PHONECALL: "phoneCall",
  FOLLOWUP: "followUp",
  NEWTASK: "newTask",
  DELETE: "delete",
  CANCEL: "cancel",
  DOWNLOAD: "download",
  DESVINCULATE: "desvinculate",
  VIEW: "view",
  VINCULATE: "vinculate",
  SENDBYEMAIL: "sendByEmail",
  SEEDETAIL: "seeDetail",
  OPENDOCUMENT: "openDocument",
  APPROVEOPPORTUNITY: "approveOpportunity",
  PAY: "pay",
};

export const KEY_GOOGLE_MAPS = "AIzaSyC8gOTtSE1DWjUY9DCM1f3JMcr19QjH-LI";

export const EMAIL_SUPPPORT = "support@potholerepair.com";
