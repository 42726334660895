import { useEffect, useState } from "react";
import { Divider, Image, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import NOIMG from "../../../assets/Image_not_available.png";
import "./OpenModalImages.css";
import { FILES_URL } from "../../../config/config";

export function OpenModalImages({ dataActive }) {
  console.log("data active pothos", dataActive);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(
      "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
    )
      .then((res) => res.json())
      .then((body) => {
        setData([...data, ...body.results]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="containerTitleModalImage">
        <div className="beforeAndAfterContainer">
          <span className="titleModalImages">Before</span>
        </div>
        <div className="beforeAndAfterContainer">
          <span className="titleModalImages">After</span>
        </div>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: 500,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={dataActive?.quotes.length}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>It is all, nothing more</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={dataActive?.quotes.filter(
              (item) => item.product === "TR"
            )}
            renderItem={(item, index) => (
              <List.Item
                key={index}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="titleQuoteContainer">
                  <span>Pothole # {item.phNumber}</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      textAlign: "center",
                    }}
                  >
                    <Image
                      src={
                        item.phImage === "NO-IMAGE"
                          ? NOIMG
                          : `${FILES_URL}/${item.phImage}`
                      }
                      alt="img 1"
                      style={{ height: "200px" }}
                    ></Image>
                  </div>
                  <div style={{ width: "40%", textAlign: "center" }}>
                    <Image
                      src={
                        item.phFinishedImage === "NO-IMAGE"
                          ? NOIMG
                          : `${FILES_URL}/${item.phFinishedImage}`
                      }
                      alt="img 2"
                      style={{ height: "200px" }}
                    ></Image>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
    </>
  );
}
