import { Steps } from "antd";
import React, { useState, useEffect } from "react";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { OpenModalView } from "../OpenModalView";

import { OpenDocumentList } from "../../Dashboard";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import moment from "moment";
import { OpenModalOpportunieties } from "../OpenModalOpportunieties";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { InvoicesList } from "../../Invoices/InvoicesList/InvoicesList";

export function OpenModalAccount({ data }) {
  const [documentsArray, setDocumentsArray] = useState([]);
  const [opportunitiesArray, setOpportunitiesArray] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const getDocuments = async () => {
    if (data.companyName) {
      return;
    }

    const url = `${BACKEND_URL}/documentmasters/getByAccountId/${data._id}`;

    const documents = await axios.get(url).catch((err) => console.log(err));

    if (documents?.data) {
      const dataElement = [];
      if (documents && documents.data.length > 0) {
        documents.data.forEach((document) => {
          dataElement.push({
            documentName: document.documentName,
            documentType: document.documentType.name,
            uploadedDate: moment(document.created).format("MM/DD/YYYY"),
            documentPath: document.documentPath,
          });
        });
      }
      setDocumentsArray(dataElement);
    }
  };

  const getOpportunity = async () => {
    let url;
    if (data.companyName) {
      url = `${BACKEND_URL}/opportunities/getOpportunityByLeadId/${data._id}`;
    } else {
      url = `${BACKEND_URL}/opportunities/getOpportunityByAccountId/${data._id}`;
    }

    const opportunity = await axios.get(url).catch((err) => console.log(err));

    if (opportunity?.data) {
      setOpportunitiesArray(opportunity.data);
    }
  };

  const getInvoices = async () => {
    if (data.companyName) {
      return;
    }
    const url = `${BACKEND_URL}/invoices/ByAccountId/${data._id}`;

    const invoices = await axios.get(url).catch((err) => console.log(err));

    if (invoices?.data) {
      console.log("invoices data", invoices.data);
      setInvoices(invoices.data);
    }
  };

  useEffect(() => {
    setDocumentsArray([]);
    setOpportunitiesArray([]);
    setInvoices([]);
    getDocuments();
    getOpportunity();
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [stepNext, setStepNext] = useState(0);
  return (
    <div style={{ minHeight: "70vh" }}>
      <Steps
        size="small"
        current={stepNext}
        onChange={(v) => setStepNext(v)}
        items={[
          {
            title: "Information",
            icon: <AssignmentLateOutlinedIcon />,
          },
          {
            title: "Proposals",
            icon: <EmojiObjectsOutlinedIcon />,
          },
          {
            title: "Invoices",
            icon: <ReceiptOutlinedIcon />,
          },
          {
            title: "Documents",
            icon: <DifferenceOutlinedIcon />,
          },
        ]}
        style={{ paddingBottom: "1rem" }}
      />

      {stepNext === 0 && <OpenModalView data={data} />}
      {stepNext === 1 && <OpenModalOpportunieties data={opportunitiesArray} />}
      {stepNext === 2 && (
        <InvoicesList title="Invoices" invoices={invoices} pageSize={5} />
      )}
      {stepNext === 3 && (
        <OpenDocumentList data={documentsArray} title="Documents" />
      )}
    </div>
  );
}
