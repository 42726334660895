import { func, number, oneOfType, string } from "prop-types";

import noControlledAirportMarkerPin from "./nocontrolled-airport-pin.png";

const Marker = ({
  className,
  lat,
  lng,
  markerId,
  markerObj,
  onClick,
  ...props
}) => {
  let markerIcon = noControlledAirportMarkerPin;

  return (
    <img
      // className={className}
      src={markerIcon}
      // eslint-disable-next-line react/no-unknown-property
      lat={lat}
      // eslint-disable-next-line react/no-unknown-property
      lng={lng}
      // onClick={(e) =>
      //   onClick ? onClick(e, { markerObj, markerId, lat, lng }) : null
      // }
      style={{ cursor: "pointer", fontSize: 40 }}
      alt={markerId}
      {...props}
    />
  );
};

Marker.defaultProps = {};

Marker.propTypes = {
  className: string,
  /**
   * The id of the marker.
   */
  markerId: oneOfType([number, string]).isRequired,
  /**
   * The latitude of the marker.
   */
  lat: number.isRequired,
  /**
   * The longitude of the marker.
   */
  lng: number.isRequired,
  /**
   * The function to call when the marker is clicked.
   */
  onClick: func,
};

export default Marker;
