import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader.js";
import { BACKEND_URL } from "../../config/config";
import axios from "axios";
import PropsalList from "../../components/Proposal/PropsalList/PropsalList";
import { useAuth } from "../../hooks";

export function Proposals() {
  const [isLoading, setIsLoading] = useState(false);
  const [proposal, setProposal] = useState([]);
  const { user } = useAuth();
  const [reload, setReload] = useState(false);

  const getData = async () => {
    setIsLoading(true);

    const url = `${BACKEND_URL}/customers/getOpportunityByContactID?contact=${user._id}`;

    const dataDashboard = await axios.get(url).catch((err) => console.log(err));

    if (dataDashboard?.data) {
      const payload = dataDashboard?.data;

      setProposal(payload);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log("No  data retrieved");
    }
  };

  const getRefreshData = async () => {
    const url = `${BACKEND_URL}/customers/getOpportunityByContactID?contact=${user._id}`;

    const dataDashboard = await axios.get(url).catch((err) => console.log(err));

    if (dataDashboard?.data) {
      const payload = dataDashboard?.data;

      setProposal(payload);
    } else {
      setIsLoading(false);
      console.log("No data retrieved");
    }
  };

  const handleRefreshChange = () => {
    getRefreshData();
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reload]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <PropsalList
          title="My Proposals"
          proposal={proposal}
          addDesactivated={true}
          setReload={setReload}
          reload={reload}
          handleRefreshChange={handleRefreshChange}
        />
      )}
    </div>
  );
}
