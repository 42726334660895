import { notification, Radio, Space, Upload, Button } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { TextField } from "@mui/material";
import { useAuth } from "../../../hooks";
import { BACKEND_URL, FRONTEND_URL } from "../../../config/config";
import axios from "axios";
import { BACKEND_USER } from "../../../config/constants";

export default function OpenContentApprove({
  setIsVisibleModal,
  proposal,
  handleRefreshChange,
}) {
  const [value, setValue] = useState(null);
  const [poNumber, setPoNumber] = useState(null);
  const [document, setDocument] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const callOnClickApproval = async () => {
    const trimmedPoNumber = poNumber ? poNumber.trim() : null;

    if (
      trimmedPoNumber === undefined ||
      trimmedPoNumber === null ||
      trimmedPoNumber === ""
    ) {
      notification["error"]({
        message: "The PO# is required to proceed.",
      });
      return;
    }
    setLoading(true);
    const url = `${BACKEND_URL}/approveProposal`;
    const data = {
      id: proposal._id,
      contact: user.fullName,
      PONumber: poNumber,
      currentUser: {
        _id: user._id,
        roles: ["user"],
      },
      approveType: "One click approval",
    };

    const response = await axios.put(url, data).catch((err) => {
      setLoading(false);
      console.log(err);
    });

    if (response?.data) {
      notification["success"]({
        message: "Approved Successfully",
      });
      handleRefreshChange();
      setDocument([]);
      setPoNumber("");
      setIsVisibleModal(false);
      setLoading(false);
    }
  };

  const callHelloSign = async () => {
    setLoading(true);
    const urlDocument = `${BACKEND_URL}/documentmasters/getByOpportunityId/${proposal._id}`;

    const documents = await axios.get(urlDocument).catch((err) => {
      setLoading(false);
      console.log("err documents opportunity call HelloSign ", err);
    });
    if (documents?.data) {
      console.log("documents backend", documents.data);
      const documentsFilter = documents?.data
        .filter((doc) => doc.documentType._id === "PRDOC")
        .sort((a, b) => new Date(b.created) - new Date(a.created));

      if (documentsFilter.length > 0) {
        const document = documentsFilter[0];

        const payload = {
          currentUser: BACKEND_USER,
          title: document.documentName, // Proposal + SiteName + Date
          subject: "Signature request", // Signature request + - SiteName
          message:
            "Please sign this document to be ready for work. Let me know if you have any question.",
          signers: [
            {
              email_address: user.email.main,
              name: user.firstName + " " + user.lastName,
            },
          ],
          files: "/uploaded/files/" + document.documentPath,
          sender: user._id,
          documents: [document._id],
          documentType: document.documentType._id,
        };

        const url = `${BACKEND_URL}/esigns`;

        const helloSign = await axios.post(url, payload).catch((err) => {
          setLoading(false);

          console.log("error helloSign", err);
        });

        if (helloSign?.data) {
          const urlHello = `${BACKEND_URL}/approveProposalWithHelloSign`;
          const hsData = {
            id: proposal._id,
            contact: user.fullName,
            PONumber: poNumber,
            currentUser: {
              _id: user._id,
              roles: ["user"],
            },
            approveType: "Approve with HelloSign",
          };

          const response = await axios.put(urlHello, hsData).catch((err) => {
            setLoading(false);

            console.log("updated oportunity", err);
          });

          if (response?.data) {
            setPoNumber("");
            setDocument([]);
            setLoading(false);
            notification["success"]({
              message: "Document Sent",
            });

            handleRefreshChange();
            setIsVisibleModal(false);
          }
        }
      } else {
        console.log("no tiene documentos");
        notification["error"]({
          message:
            "There is no Proposal Document attached to this proposal. If you want to sign it via eSign, please contact (571)-449-8010 for assistance.",
        });
        setIsVisibleModal(false);
        setPoNumber("");
        setDocument([]);
        return;
      }
    }
    return;
  };

  const callApproveAndUploadDocument = async () => {
    console.log("proposal ------>>>", proposal);

    setLoading(true);

    const documentActive = document[0].originFileObj;
    const proposalName = proposal.opportunityName
      .replace(/[^a-z0-9\s|^#]/gi, "-")
      .replace(/[#]/gi, "N")
      .replace(/[_]/gi, "-")
      .replace("&", "-");

    const fullPath = proposalName + "/Documents/" + documentActive.name;

    try {
      const url = `${FRONTEND_URL}/upload?parent=/${proposalName}&dir=/Documents`;

      let jdata = {
        opportunity: proposal._id,
        documentType: "PROPOSALDOC",
        documentName: documentActive.name,
        data: documentActive,
        documentPath: fullPath,
        currentUser: user._id,
      };

      if (proposal.account) {
        jdata.account = proposal.account._id;
      } else {
        jdata.lead = proposal.lead._id;
      }

      const uploadedDocument = await axios
        .post(url, jdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err) => {
          setLoading(false);
          console.log("error catch uploadDocument", err);
        });
      if (uploadedDocument?.data) {
        console.log("uploaded document", uploadedDocument?.data.files);
        const payloadLinkDocument = uploadedDocument.data.files;

        let dataDocument = {
          opportunity: proposal._id,
          documentType: "PROPAPR",
          documentName: payloadLinkDocument[0].name,
          documentPath: fullPath,
          currentUser: BACKEND_USER,
        };

        if (proposal.lead) {
          jdata.lead = proposal.lead;
        } else {
          jdata.account = proposal.account;
        }

        const urlDocument = `${BACKEND_URL}/documentmasters`;

        const responseDocument = await axios
          .post(urlDocument, dataDocument)
          .catch((err) => {
            setLoading(false);
            console.log("error creating document", err);
          });

        if (responseDocument.data) {
          const urlAppprove = `${BACKEND_URL}/approveProposal`;

          const data = {
            id: proposal._id,
            contact: user.fullName,
            PONumber: poNumber,
            currentUser: {
              _id: user._id,
              roles: ["user"],
            },
            approveType: "Approve and upload document",
            document: payloadLinkDocument[0].url,
          };

          const response = await axios.put(urlAppprove, data).catch((err) => {
            setLoading(false);
            console.log("approveProposal next upload document", err);
          });
          if (response.data) {
            setLoading(false);
            notification["success"]({
              message: "Approved Successfully",
            });
            handleRefreshChange();
            setIsVisibleModal(false);
            setPoNumber("");
            setDocument([]);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendData = async () => {
    if ((value === 3 && document?.length === 0) || document === null) {
      // TODO: CREAR MENSAJE PARA EL USUARIO CON LA LEYENDA DEL CONSOLE
      notification["error"]({
        message: "Document missing, please select a file to procede",
      });
    }

    if (value === 1) {
      // ONE CLICK APPROVAL
      callOnClickApproval();
    }

    if (document.length > 0 && value === 3) {
      callApproveAndUploadDocument();
    }

    if (value === 2) {
      callHelloSign();
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    setDocument(newFileList);
  };

  const cancelModal = () => {
    setPoNumber("");
    setIsVisibleModal(false);
    setDocument([]);
    setValue(null);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <span>
        You are trying to approve the proposal #{proposal._id}. Please, select
        one option
      </span>
      <div style={{ paddingTop: "1rem" }}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label="Do you have a PO#? If so, please enter here"
          onChange={(e) => setPoNumber(e.target.value)}
          value={poNumber}
        />
      </div>
      <div style={{ paddingTop: "1rem" }}>
        <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
          <Space direction="vertical">
            <Radio value={1}>One click approval</Radio>
            <Radio value={2}>Approve with HelloSign</Radio>
            <Radio value={3}>Approve and upload document</Radio>
            {value === 3 && (
              <Upload
                style={{ marginTop: "1rem" }}
                maxCount={1}
                fileList={document}
                customRequest={dummyRequest}
                onChange={onChange}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            )}
          </Space>
        </Radio.Group>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          paddingTop: "1rem",
        }}
      >
        <Button onClick={cancelModal} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={sendData}
          type="primary"
          loading={loading}
          disabled={loading}
        >
          Approve
        </Button>
      </div>
    </>
  );
}
