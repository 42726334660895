import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { Button, Image, Steps } from "antd";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { OpenInvoiceView, OpenModalImages } from "../../Invoices";
import { OpenDocumentList } from "../../Dashboard";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";
import moment from "moment";
import PASTDUE from "../../../assets/pastDue.jpg";

export function OpenModalInvoice({ data }) {
  console.log("invoice data", data);
  const [invoiceData, setInvoiceData] = useState(null);
  const [stepNext, setStepNext] = useState(0);
  const [documentsArray, setDocumentsArray] = useState([]);

  const getDocuments = async () => {
    const url = `${BACKEND_URL}/documentmasters/getByInvoiceId/` + data._id;

    const opportunityDocuments = await axios
      .get(url)
      .catch((err) => console.log(err));

    const dataElement = [];
    if (opportunityDocuments && opportunityDocuments.data.length > 0) {
      opportunityDocuments.data.forEach((document) => {
        dataElement.push({
          documentName: document.documentName,
          documentType: document.documentType.name,
          uploadedDate: moment(document.created).format("MM/DD/YYYY"),
          documentPath: document.documentPath,
        });
      });
      setDocumentsArray(dataElement);
    }
  };

  const onPay = (data) => {
    const paymentUrl = data?.invoiceQBOPaymentLink
      ? data.invoiceQBOPaymentLink
      : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    window.open(
      paymentUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    );
    // const paymentUrl = rowData?.invoiceQBOPaymentLink
    //   ? rowData.invoiceQBOPaymentLink
    //   : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    // window.open(
    //   paymentUrl,
    //   "_blank",
    //   "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    // );

    // setIsVisibleModal(true);
    // setModalTitle(`Pay Invoice # ${rowData.invoiceNo}`);
    // setModalContent(
    //   <>
    //     <OpenModalPay />
    //   </>
    // );
  };

  const getDataInvoice = async () => {
    const url = `${BACKEND_URL}/invoices/${data._id}`;

    const invoice = await axios.get(url).catch((err) => console.log(err));

    if (invoice.data) {
      const payload = invoice.data;

      setInvoiceData(payload);
    }
  };

  useEffect(() => {
    getDataInvoice();
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const days = invoiceData?.paymentTerms?.days || 0;

  const addedDate = moment(invoiceData?.dateService).add(days, "days");

  return (
    <div style={{ minHeight: "60vh", position: "relative" }}>
      <Steps
        size="small"
        current={stepNext}
        onChange={(v) => setStepNext(v)}
        items={[
          {
            title: "Information",
            icon: <AssignmentLateOutlinedIcon />,
          },
          {
            title: "Images",
            icon: <CollectionsIcon />,
          },

          {
            title: "Documents",
            icon: <DifferenceOutlinedIcon />,
          },
        ]}
        style={{ paddingBottom: "1rem" }}
      />
      {invoiceData?.invoiceStatus?._id !== "COMMPAID" &&
        invoiceData?.invoiceStatus?._id !== "JCIS" &&
        new Date(addedDate) < today && (
          <Image
            src={PASTDUE}
            alt="imgDue"
            preview={false}
            style={{
              position: "absolute",
              opacity: 0.12,
              top: 40,
              left: 400,
              height: 300,
              width: 600,
            }}
          ></Image>
        )}

      {stepNext === 0 && <OpenInvoiceView dataActive={invoiceData} />}
      {stepNext === 1 && <OpenModalImages dataActive={invoiceData} />}

      {stepNext === 2 && (
        <OpenDocumentList data={documentsArray} title="Invoice Documents" />
      )}
      {invoiceData?.invoiceStatus?._id === "JCIS" &&
        invoiceData.invoiceQBOPaymentLink && (
          <div
            style={{
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => onPay(data)}>
              <div style={{ display: "flex", fontWeight: "bold" }}>
                Pay
                <PaidOutlinedIcon
                  style={{ color: "#008f39", paddingLeft: "10px" }}
                />
              </div>
            </Button>
          </div>
        )}
    </div>
  );
}
