import React, { useState } from "react";
import Loader from "../../Loader/Loader";
import { Col, Divider, Row } from "antd";
import { TextField } from "@mui/material";
import { THEME_COLORS } from "../../../config/constants";
import moment from "moment";
import { currencyFormatter } from "../../../helpers/currencyHelper";

export function OpenProposalView({ dataActive }) {
  console.log("data activa mi proposal", dataActive);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  if (dataActive === null) {
    return <Loader />;
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row gutter={24} style={{ paddingTop: "1rem" }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Proposal"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.proposalNo}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Opportunity Name"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.opportunityName}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                id="standard-basic"
                label="Stage"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.region}
              />
              {/* <Button
                style={{ marginLeft: "5px" }}
                onClick={() => openMap(dataActive)}
              >
                Ver
              </Button> */}
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Closing Date"
                variant="outlined"
                fullWidth
                size="small"
                value={moment(dataActive?.closingDate).format("MM/DD/YYYY")}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Amount"
                variant="outlined"
                fullWidth
                size="small"
                value={currencyFormatter(dataActive?.amount)}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Requested Date"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={moment(dataActive?.requestedDate).format("MM/DD/YYYY")}
              />
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Valid Until"
                variant="outlined"
                fullWidth
                size="small"
                value={moment(dataActive?.validUntil).format("MM/DD/YYYY")}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="PARC Agreement"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.PARCAgreement?.name}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Total TR’s"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.numberTRs}
              />
            </Col>
          </Row>

          <Divider
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              color: THEME_COLORS.NARANJA,
            }}
          >
            Address Information
          </Divider>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="State"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={
                  dataActive?.account
                    ? dataActive?.account?.state?.name
                    : dataActive?.lead.state?.name
                }
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="City"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={
                  dataActive?.account
                    ? dataActive?.account?.city?.name
                    : dataActive?.lead.city?.name
                }
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Street"
                variant="outlined"
                fullWidth
                size="small"
                required
                value={
                  dataActive?.account
                    ? dataActive?.account?.street
                    : dataActive?.lead.street
                }
              />
            </Col>
          </Row>
          <Divider
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              color: THEME_COLORS.NARANJA,
            }}
          >
            Contact Information
          </Divider>

          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Name"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.contact?.fullName}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="email"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.contact?.email?.main}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Street"
                variant="outlined"
                fullWidth
                size="small"
                required
                value={dataActive?.contact?.phone?.main}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
