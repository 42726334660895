import React from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
// eslint-disable-next-line no-unused-vars
import { Button, Modal as ModalAntd } from "antd";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import {
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
  FileDoneOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
  CloseCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
// import MaterialTableLocalization from "../../localization/materialTable.localization";
// import LinkOffIcon from "@mui/icons-material/LinkOff";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import "./GenericList.css";
import { DownloadOutlined } from "@mui/icons-material";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

// const { confirm } = ModalAntd;

export default function GenericList(props) {
  const {
    columns,
    data,
    actions,
    pageSize,
    title,
    onEditAction,
    onCreateButtonClick,
    onDeleteAction,
    onCancelAction,
    onDownloadAction,
    addDesactivated,
    onView,
    onSendByEmail,
    onSeeDetail,
    onOpenDocuments,
    onApproveOpportunity,
    onPay,
  } = props;

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  const [filtering, setFiltering] = React.useState(false);

  const actionsList = {
    edit: {
      icon: EditOutlined,
      tooltip: "Edit",
      onClick: (event, rowData) => onEditAction(rowData._id),
    },
    email: {
      icon: MailOutlined,
      tooltip: "Email",
      onClick: (event, rowData) => sendEmailAction(rowData.email || ""),
    },
    whatsApp: {
      icon: WhatsAppOutlined,
      tooltip: "contact by WhatsApp",
      onClick: (event, rowData) => sendWhatsappAction(rowData.cellPhone || ""),
    },
    phoneCall: {
      icon: PhoneOutlined,
      tooltip: "contact by phone",
      onClick: (event, rowData) => callByPhoneAction(rowData.phone || ""),
    },
    newTask: {
      icon: FileDoneOutlined,
      tooltip: "Add task",
    },
    delete: {
      icon: DeleteOutlined,
      tooltip: "Delete",
      onClick: (event, rowData) => onDeleteAction(rowData._id),
    },
    cancel: {
      icon: CloseCircleOutlined,
      tooltip: "Cancel",
      onClick: (event, rowData) => onCancelAction(rowData._id),
    },
    download: {
      icon: DownloadOutlined,
      tooltip: "Download",
      onClick: (event, rowData) => onDownloadAction(rowData),
    },
    pay: {
      icon: PaidOutlinedIcon,
      tooltip: "Pay",
      onClick: (event, rowData) => onPay(rowData),
      hidden: (event, rowData) => !(rowData?.invoiceStatus?._id === "JCIS"),
    },

    view: {
      icon: EyeOutlined,
      tooltip: "View detail",
      onClick: (event, rowData) => onView(rowData),
    },
    openDocument: {
      icon: FileOpenOutlinedIcon,
      tooltip: "Open Documents",
      onClick: (event, rowData) => onOpenDocuments(rowData),
    },
    approveOpportunity: {
      icon: TaskAltOutlinedIcon,
      tooltip: "Approve Proposal",
      onClick: (event, rowData) => onApproveOpportunity(rowData),
      hidden: (event, rowData) =>
        rowData.approvedByCustomer === true ||
        rowData.status._id !== "PROPOSAL" ||
        rowData.amount <= 0,
    },

    sendByEmail: {
      icon: ForwardToInboxIcon,
      tooltip: "Send by email",
      onClick: (event, rowData) => onSendByEmail(rowData),
    },
    seeDetail: {
      icon: EyeOutlined,
      tooltip: "See detail",
      onClick: (event, rowData) => onSeeDetail(rowData),
    },
  };

  const actionsSelected = actions.map((action) => {
    return actionsList[action];
  });

  function sendWhatsappAction(cellPhone) {
    if (cellPhone) {
      window.open(`https://wa.me/${cellPhone}`);
    }
  }

  function sendEmailAction(email) {
    const mailto = `mailto:${email}`;
    window.open(mailto);
  }

  function callByPhoneAction(phoneNumber) {
    const call = `tel:${phoneNumber}`;
    window.open(call);
  }

  return (
    <>
      <div className="listContainer">
        <ThemeProvider theme={theme}>
          <MaterialTable
            // localization={MaterialTableLocalization}
            columns={columns}
            className="genericTable"
            data={data}
            title={title}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <MTableToolbar {...props} />
                  </div>
                  <Button
                    onClick={() => {
                      setFiltering((currentFilter) => !currentFilter);
                    }}
                  >
                    <FilterOutlined />
                  </Button>
                  {addDesactivated ? null : (
                    <Button
                      type="primary"
                      onClick={() => {
                        onCreateButtonClick();
                      }}
                      style={{
                        marginLeft: "6px",
                        backgroundColor: "#00909E",
                        color: "#fff",
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  )}
                </div>
              ),
            }}
            actions={actionsSelected}
            options={{
              actionsColumnIndex: -1,
              pageSize: pageSize || 10,
              filtering,
              exportMenu: [
                {
                  label: "Exportar PDF",
                  exportFunc: (cols, datas) =>
                    ExportPdf(cols, datas, "reporte"),
                },
                {
                  label: "Exportar CSV",
                  exportFunc: (cols, datas) =>
                    ExportCsv(cols, datas, "reporte"),
                },
              ],
            }}
            style={{ paddingRight: "20px" }}
          />
        </ThemeProvider>
      </div>
    </>
  );
}
