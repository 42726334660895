import React, { useState, useEffect } from "react";
import Logo from "../../../assets/logo.png";
import { Form, Input, Button, notification } from "antd";
import { requestAccessApi, signInApi } from "../../../api/auth";
import { useAuth } from "../../../hooks";
import "./LoginForm.css";
import {
  EMAIL,
  USER,
  DISPLAYNAME,
  HASH_TOKEN,
  ACCOUNTS,
  EMAIL_SUPPPORT,
} from "../../../config/constants";
import Modal from "../../Modal/Modal";
import ForgotPassword from "../../ForgotPassword/ForgotPassword";

import ChangePassword from "../../ChangePassword/ChangePassword";

export function LoginForm() {
  const { login } = useAuth();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    const modal = urlSearchParams.get("modal");

    if (modal === "changePassword" && token) {
      openModalChangePassword(token);
    }
  }, []);

  const openModalChangePassword = (token) => {
    setIsVisibleModal(true);
    setModalTitle("Change Password");
    setModalContent(
      <>
        <ChangePassword tokened={token} setIsVisibleModal={setIsVisibleModal} />
      </>
    );
  };

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  function sendEmailAction() {
    const mailto = `mailto:${EMAIL_SUPPPORT}`;
    window.open(mailto);
  }

  const singIn = async (e) => {
    setLoading(true);
    // const accessToken = "afdasdfasdf432343dfasdfasdf";

    if (inputs.email === "" && inputs.password === "") {
      notification["error"]({
        message: "Please fill in all the fields",
      });
      setLoading(false);
      return;
    }

    if (inputs.email === "") {
      notification["error"]({
        message: "Please fill in the username field",
      });
      setLoading(false);
      return;
    }

    if (inputs.password === "") {
      notification["error"]({
        message: "Please fill in the password field",
      });
      setLoading(false);
      return;
    }

    const result = await signInApi(inputs);

    if (result.code === -1) {
      notification["error"]({
        message: result.message,
      });
      setLoading(false);
    }

    if (result.code === 0) {
      const { fullName, email, hashToken, accounts } = result?.data;

      localStorage.setItem(DISPLAYNAME, fullName);
      localStorage.setItem(USER, JSON.stringify(result.data));
      localStorage.setItem(EMAIL, email?.main);
      localStorage.setItem(HASH_TOKEN, hashToken);
      localStorage.setItem(ACCOUNTS, JSON.stringify(accounts));

      login(result.data, hashToken);
      setLoading(false);
    }
  };

  const createHash = async () => {
    setLoading(true);
    // const accessToken = "afdasdfasdf432343dfasdfasdf";
    try {
      if (inputs.email === "" && inputs.password === "") {
        notification["error"]({
          message: "Please fill in all the fields",
        });
        setLoading(false);
        return;
      }

      if (inputs.email === "") {
        notification["error"]({
          message: "Please fill in the username field",
        });
        setLoading(false);
        return;
      }

      if (inputs.password === "") {
        notification["error"]({
          message: "Please fill in the password field",
        });
        setLoading(false);
        return;
      }

      const result = await requestAccessApi(inputs);

      if (result.code === -1) {
        notification["error"]({
          message: result.message,
        });
        setLoading(false);
      }

      if (result.code === 0) {
        notification["success"]({
          message: result.message,
        });
        setLoading(false);
      }
    } catch (e) {
      console.log("Error request access", e);
      notification["error"]({
        message: e,
      });
      setLoading(false);
    }
  };

  const openModalForgotPassword = () => {
    setIsVisibleModal(true);
    setModalTitle("Forgot Password");
    setModalContent(
      <>
        <ForgotPassword
          setIsVisibleModal={setIsVisibleModal}
          isVisibleModal={isVisibleModal}
        />
      </>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loginForm">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          className="containerLogo"
        >
          <img src={Logo} alt="Logo" className="centeredLogo" />
        </div>
        <Form className="containerControls" onFinish={singIn}>
          <div style={{ paddingBottom: "3vh", width: "100%" }}>
            <label className="loginInputLabel">User Name</label>
            <Input
              name="email"
              placeholder="Username"
              className="login-form__input"
              onChange={changeForm}
            />
          </div>
          <label className="loginInputLabel">Password</label>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            className="login-form__input"
            onChange={changeForm}
          />
          <div className="containerLoginButton">
            <Button
              className="login-form__button"
              loading={loading}
              htmlType="submit"
            >
              Login
            </Button>
          </div>
          <div
            style={{
              paddingTop: "25px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <span
              className="buttonRecuperarContraseña"
              onClick={openModalForgotPassword}
            >
              Forgot password
            </span>
            <span style={{ color: "#ccc" }}>|</span>
            <span className="buttonRecuperarContraseña" onClick={createHash}>
              Request access
            </span>
          </div>
          <div
            style={{
              paddingTop: "15px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <span
              className="buttonRecuperarContraseña"
              onClick={sendEmailAction}
            >
              Contact Support
            </span>
          </div>
        </Form>
      </div>
      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        // className={"openModalDocumentsList"}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
