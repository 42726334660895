const BACKEND_URL =
  process.env.BACKEND_URL || "https://crm.potholerepair.com/api";
const FRONTEND_URL =
  process.env.FRONTEND_URL || "https://crm.potholerepair.com";
const FILES_URL =
  process.env.FILES_URL || "https://crm.potholerepair.com/uploaded/files";

module.exports = {
  BACKEND_URL,
  FRONTEND_URL,
  FILES_URL,
};
