import React from "react";
import { LoginForm } from "../../components/Auth";
import "./Auth.css";

export function Auth() {
  return (
    <div className="sign-in-Page">
      <LoginForm />
    </div>
  );
}
