import React, { useState, useEffect } from "react";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import { currencyFormatter } from "../../../helpers/currencyHelper";
import moment from "moment";
import { OpenDocumentList } from "../../Dashboard";
import { Button } from "antd";
import Modal from "../../Modal/Modal";
import { OpenModalProposal } from "../OpenModalProposal/OpenModalProposal";
import OpenContentApprove from "../OpenContentApprove/OpenContentApprove";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { OpenModalJobTimeLine } from "../OpenModalJobTimeLine/OpenModalJobTimeLine";

export default function PropsalList({
  proposal,
  title,
  pageSize,
  addDesactivated,
  reload,
  setReload,
  handleRefreshChange,
  dataChart,
}) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [filtering, setFiltering] = React.useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Función de actualización del tamaño de la ventana
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Suscribirse al evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el efecto cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  const proposalscolumns = [
    {
      title: "Company",
      field: "account.accountName",
      render: (rowData) => {
        return (
          <span>
            {rowData?.account?.accountName || rowData?.lead.companyName}
          </span>
        );
      },
    },

    {
      title: "Proposal number",
      field: "proposalNo",
    },
    {
      title: "Proposal name",
      field: "opportunityName",
    },
    {
      title: "Total TR's",
      field: "numberTRs",
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => <span>{currencyFormatter(rowData?.amount)}</span>,
    },
    {
      title: "Stage",
      field: "status.description",
    },
    {
      title: "PARC",
      field: "PARCAgreement.name",
    },
    {
      title: "Branch",
      field: "region",
    },
    {
      title: "Requested Date",
      field: "requestedDate",
      render: (rowData) => (
        <span>{moment(rowData?.requestedDate).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Valid Until",
      field: "validUntil",
      render: (rowData) => (
        <span>{moment(rowData?.validUntil).format("MM/DD/YYYY")}</span>
      ),
    },
  ];

  const showProposalDetail = async (data) => {
    setIsVisibleModal(true);
    setModalTitle(`Proposal detail # ${data.proposalNo}`);
    setModalContent(
      <>
        <OpenModalProposal
          proposal={data}
          ApproveOpportunity={ApproveOpportunity}
          setReload={setReload}
          reload={reload}
          handleRefreshChange={handleRefreshChange}
        />
      </>
    );
  };

  const showProposalDocumentsList = async (data) => {
    const url = `${BACKEND_URL}/documentmasters/getByOpportunityId/` + data._id;

    const opportunityDocuments = await axios
      .get(url)
      .catch((err) => console.log(err));

    const dataElement = [];
    if (opportunityDocuments && opportunityDocuments.data.length > 0) {
      opportunityDocuments.data.forEach((document) => {
        dataElement.push({
          documentName: document.documentName,
          documentType: document.documentType.name,
          uploadedDate: moment(document.created).format("MM/DD/YYYY"),
          documentPath: document.documentPath,
        });
      });
      setIsVisibleModal(true);
      setModalTitle("Proposal Documents");
      setModalContent(
        <div style={{ width: "100%" }}>
          <OpenDocumentList data={dataElement} title="" />
        </div>
      );
    } else {
      setIsVisibleModal(true);
      setModalTitle("Proposal Documents");
      setModalContent(
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontWeight: "bold" }}>No documents</span>
        </div>
      );
    }
  };

  const ApproveOpportunity = (data) => {
    setIsVisibleModal(true);
    setModalTitle("Approve this proposal");
    setModalContent(
      <OpenContentApprove
        setIsVisibleModal={setIsVisibleModal}
        proposal={data}
        reload={reload}
        setReload={setReload}
        handleRefreshChange={handleRefreshChange}
      />
    );
  };

  const jobTimeLineModal = (data) => {
    setIsVisibleModal(true);
    setModalTitle(`Proposal job timeline # ${data.proposalNo}`);
    setModalContent(
      <>
        <OpenModalJobTimeLine proposal={data} />
      </>
    );
  };

  return (
    <>
      <div className="listContainer">
        <ThemeProvider theme={theme}>
          <MaterialTable
            columns={proposalscolumns}
            className="genericTable"
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <MTableToolbar {...props} />
                  </div>
                  <Button
                    onClick={() => {
                      setFiltering((currentFilter) => !currentFilter);
                    }}
                  >
                    <FilterOutlined />
                  </Button>
                </div>
              ),
            }}
            data={proposal}
            actions={
              dataChart
                ? []
                : [
                    (rowData) => ({
                      icon: EyeOutlined,
                      tooltip: "View",
                      onClick: (event, rowData) => {
                        showProposalDetail(rowData);
                        // ...
                      },
                      // disabled: rowData.view, // Desactivar el botón si el atributo "view" es false
                      // hidden: rowData.view, // Ocultar el botón si el atributo "view" es false
                    }),
                    (rowData) => ({
                      icon: FileOpenOutlinedIcon,
                      tooltip: "Open Documents",
                      onClick: (event, rowData) => {
                        showProposalDocumentsList(rowData);
                        // Acción que ocurre cuando se hace clic en el botón "View"
                        // ...
                      },
                      // disabled:
                      //   rowData.PARCAgreement &&
                      //   rowData.PARCAgreementEndDate < today, // Desactivar el botón si el atributo "view" es false
                      // hidden: rowData.PARCAgreement &&
                      // rowData.PARCAgreementEndDate < today, // Ocultar el botón si el atributo "view" es false
                    }),
                    (rowData) => ({
                      icon: TaskAltOutlinedIcon,
                      tooltip: "Approve Proposal",
                      onClick: (event, rowData) => {
                        ApproveOpportunity(rowData);
                        // ...
                      },
                      disabled:
                        rowData.approvedByCustomer === true ||
                        rowData.status._id !== "PROPOSAL" ||
                        rowData.amount <= 0,
                      hidden:
                        rowData.approvedByCustomer === true ||
                        rowData.status._id !== "PROPOSAL" ||
                        rowData.amount <= 0,
                    }),
                    (rowData) => ({
                      icon: LocalShippingOutlinedIcon,
                      tooltip: "Job timeline",
                      onClick: (event, rowData) => {
                        jobTimeLineModal(rowData);
                        // ...
                      },

                      hidden: rowData.jobTimeline.length <= 0,
                    }),
                  ]
            }
            options={{
              actionsColumnIndex: -1,
              pageSize: pageSize || 20,
              filtering,
              headerStyle: { position: "sticky", top: 0, zIndex: 100 },
              maxBodyHeight: `${windowSize.height - 250}px`,
              minBodyHeight: `${windowSize.height - 250}px`,
              exportMenu: [
                {
                  label: "Export to PDF",
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, "Report"),
                },
                {
                  label: "Export to CSV",
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, "Report"),
                },
              ],
            }}
            style={{ paddingRight: "20px" }}
            title={title}
            // onCreateButtonClick={createAction}
            // onDeleteAction={deleteAction}
            // onEditAction={editAction}
          />
        </ThemeProvider>
      </div>

      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        className={
          modalTitle === "Approve this proposal"
            ? null
            : "openModalDocumentsList"
        }
      >
        {modalContent}
      </Modal>
    </>
  );
}
