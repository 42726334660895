import React, { useState } from "react";
import { Button, Image, Checkbox } from "antd";
import Modal from "../../Modal/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { CameraAltOutlined } from "@mui/icons-material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { currencyFormatter } from "../../../helpers/currencyHelper";
import { BACKEND_URL, FILES_URL } from "../../../config/config";
import NoImage from "../../../assets/Image_not_available.png";

import axios from "axios";
import "./OpenQuotesProposal.css";

export function OpenQuotesProposal({
  dataActive,
  reload,
  setReload,

  handleRefreshChange,
}) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [filtering, setFiltering] = React.useState(false);

  const addDesactivated = true;
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  const tableStyle = {
    maxHeight: "50vh",
    overflowY: "auto",
    paddingRight: "20px",
  };

  const openMap = (lat, lng) => {
    const url = `https://maps.google.com/?q=${lat},${lng}`;

    window.open(url, "_blank");
  };

  const onChange = async (e, dataItem) => {
    // if (!dataActive._id || dataActive?.contact?.fullName) {
    //   return;
    // }

    if (e.target.checked === true) {
      const dataSend = {
        opportunity: dataActive._id,
        item: dataItem.phNumber,
        exclusionType: "EXCLUDE",
        contact: dataActive?.contact?.fullName,
      };

      const url = `${BACKEND_URL}/calculeProposalPrice`;
      const { data } = await axios.post(url, dataSend).catch((err) => {
        console.log(err);
      });

      if (data) {
        setReload(!reload);
        handleRefreshChange();
      }
    } else {
      const dataSend = {
        opportunity: dataActive._id,
        item: dataItem.phNumber,
        exclusionType: "INCLUDE",
        contact: dataActive.contact.fullName,
      };

      const url = `${BACKEND_URL}/calculeProposalPrice`;
      const { data } = await axios.post(url, dataSend).catch((err) => {
        console.log(err);
      });

      if (data) {
        setReload(!reload);
        handleRefreshChange();
      }
    }
  };

  const getDescription = (code) => {
    switch (code) {
      case "TR":
        return "Thermal Repair";
      case "PARC":
        return "PARC Agreement";
      case "FSC":
        return "Fuel Surcharge";
      case "ALB":
        return "Added Labor";
      case "EMERFEE":
        return "Emergency Fee";
      case "CS":
        return "Chushed Stone";
      case "DEMO":
        return "Demo TR";
      case "RISE":
        return "Riser";
      case "SPDSC":
        return "Special Discount";
      default:
        return "Generic Product";
    }
  };

  const quoteCoulumns = [
    {
      title: "Pothole #",
      field: "phNumber",
    },
    {
      title: "Product Type",
      field: "product",
      render: (rowData) => <span>{getDescription(rowData?.product)}</span>,
    },
    {
      title: "# Units",
      field: "quantity",
    },
    {
      title: "Adj. Total",
      field: "adjustedAmount",
      render: (rowData) => <>{currencyFormatter(rowData.adjustedAmount)}</>,
    },
    {
      title: "Options",
      field: "",
      render: (rowData) => (
        <div>
          <CameraAltOutlined
            style={{ color: "#298000", cursor: "pointer" }}
            onClick={() => openImage(rowData)}
          />
          <StickyNote2OutlinedIcon
            style={{ color: "#FCD35D", marginLeft: "1rem", cursor: "pointer" }}
            onClick={() => openNotes(rowData)}
          />
        </div>
      ),
    },
    {
      title: "Map Pos",
      field: "position",
      render: (rowData) =>
        rowData.position &&
        rowData.position?.lat !== "0" &&
        rowData.position?.lng !== "0" ? (
          <LocationOnOutlinedIcon
            style={{
              color: "#008f39",
              marginLeft: "1rem",
              cursor: "pointer",
            }}
            onClick={() =>
              openMap(rowData.position?.lat, rowData.position?.lng)
            }
          />
        ) : null,
    },
    {
      title: "Excluded",
      field: "excluded",
      render: (rowData) => (
        <Checkbox
          onChange={(e) => onChange(e, rowData)}
          style={{ paddingLeft: "1.2rem" }}
          defaultChecked={rowData.excluded}
        ></Checkbox>
      ),
    },
  ];

  const openImage = (data) => {
    setIsVisibleModal(true);
    setModalTitle(`Image Quote #  ${data.phNumber}`);
    setModalContent(
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        {data.phImage !== "NO-IMAGE" && (
          <Image
            src={`${FILES_URL}/${data.phImage}`}
            alt="image quote"
            width={400}
          />
        )}

        {data.phImage === "NO-IMAGE" && (
          <Image src={NoImage} alt="not image quote" width={400} />
        )}
      </div>
    );
  };

  const openNotes = (data) => {
    setIsVisibleModal(true);
    setModalTitle(`Notes Quote # ${data.phNumber}`);
    setModalContent(
      <>
        {" "}
        <p>{data?.surveyorNote || "No Notes"}</p>
      </>
    );
  };

  return (
    <>
      <div className="listContainer">
        <ThemeProvider theme={theme}>
          <MaterialTable
            columns={quoteCoulumns}
            className="genericTable"
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <MTableToolbar {...props} />
                  </div>
                  <Button
                    onClick={() => {
                      setFiltering((currentFilter) => !currentFilter);
                    }}
                  >
                    <FilterOutlined />
                  </Button>
                  {addDesactivated ? null : (
                    <Button
                      type="primary"
                      onClick={() => {}}
                      style={{
                        marginLeft: "6px",
                        backgroundColor: "#00909E",
                        color: "#fff",
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  )}
                </div>
              ),
            }}
            data={dataActive.quotes}
            options={{
              actionsColumnIndex: -1,
              // pageSize: 5,

              paging: false,
              filtering,

              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.excluded === true ? "#ff261b" : "inherit",
              }),

              exportMenu: [
                {
                  label: "Export to PDF",
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, "Report"),
                },
                {
                  label: "Export to CSV",
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, "Report"),
                },
              ],
            }}
            // style={{ paddingRight: "20px" }}
            title="Quotes"
            // onCreateButtonClick={createAction}
            // onDeleteAction={deleteAction}
            // onEditAction={editAction}

            style={tableStyle} // Aplica el estilo de scroll a la tabla
          />
        </ThemeProvider>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="totalTrs">
          <span
            style={{
              fontWeight: "bold",
              marginLeft: "5px",
              marginRight: "5px",
              fontSize: "1rem",
            }}
          >
            Total TRs:
          </span>
          <span>{dataActive.numberTRs}</span>
        </div>
        <div className="proposalPrice">
          <span
            style={{
              fontWeight: "bold",
              marginLeft: "5px",
              marginRight: "5px",
              fontSize: "1rem",
            }}
          >
            Proposal Price:
          </span>
          <span>{currencyFormatter(dataActive.amount)}</span>
        </div>
      </div>

      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </>
  );
}
