import React, { useState } from "react";
import GenericList from "../../components/GenericList/GenericList";
import { ACTION_BUTTONS } from "../../config/constants";
import Loader from "../../components/Loader/Loader.js";

export function Reports() {
  const createAction = () => {};

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "LastName",
      field: "lastName",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Phone",
      field: "cellPhone",
    },
    {
      title: "Address",
      field: "address",
    },
  ];

  const actions = [
    ACTION_BUTTONS.EDIT,
    ACTION_BUTTONS.EMAIL,
    ACTION_BUTTONS.WHATSAPP,
    ACTION_BUTTONS.DELETE,
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <GenericList
          columns={columns}
          data={[]}
          actions={actions}
          title="Report Pothole"
          onCreateButtonClick={createAction}
          // onDeleteAction={deleteAction}
          // onEditAction={editAction}
        />
      )}
    </div>
  );
}
