import React from "react";

import "./ChangePassword.css";
import { Button, Col, Row, notification } from "antd";
import {
  InputLabel,
  OutlinedInput,
  TextField,
  FormControl,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { useState } from "react";
// import { FormControl, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { BACKEND_URL } from "../../config/config";
import axios from "axios";

export default function ChangePassword({
  setIsVisibleModal,
  isVisibleModal,
  tokened,
}) {
  const [data, setData] = useState({
    code: "",
    password: "",
    repeatPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () =>
    setShowRepeatPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePasswordApi = async () => {
    setLoading(true);
    if (!data.code) {
      notification["error"]({
        message: "Please enter the code we sent you via email.",
      });
      setLoading(false);
      return;
    }

    if (!data.password || !data.repeatPassword) {
      notification["error"]({
        message: "Please enter both new password and repeat password.",
      });
      setLoading(false);
      return;
    }

    if (data.password !== data.repeatPassword) {
      notification["error"]({
        message:
          "The provided passwords do not match. Please verify and try again.",
      });
      setLoading(false);
      return;
    }

    try {
      const urlChangePassword = `${BACKEND_URL}/customers/changePassword`;
      const dataSendChangePassword = {
        temporaryCode: Number(data.code),
        id: Number(tokened),
        password: data.password,
      };

      console.log(dataSendChangePassword, urlChangePassword);
      const responseChangePassword = await axios.post(
        urlChangePassword,
        dataSendChangePassword
      );

      if (responseChangePassword?.data?.message !== "Invalid Temporary Code") {
        notification["success"]({
          message: "Your password has been updated",
        });
        setIsVisibleModal(false);
        setLoading(false);
      }

      if (responseChangePassword?.data?.message === "Invalid Temporary Code") {
        notification["error"]({
          message: "Your temporary code is invalid",
        });
      }
    } catch (e) {
      console.log("error catch changePassword", e);
      notification["error"]({
        message:
          "We apologize, but there seems to be a server issue at the moment. Please try again later. Thank you for your understanding.",
      });
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <Row gutter={24} className="marginTop">
        <Col span={24}>
          <div style={{ paddingBottom: "1.5rem" }}>
            To initiate the password change process for your account, please
            enter the verification code that was sent to your email along with
            your new password. Ensure your new password is secure, using a
            combination of letters, numbers, and special characters. Feel free
            to reach out to our support team if you encounter any difficulties
            or haven't received the verification email. Thank you for your
            cooperation!
          </div>
          <TextField
            id="standard-basic"
            label="Code"
            variant="outlined"
            fullWidth
            required
            size="small"
            value={data?.code || ""}
            onChange={(e) => setData({ ...data, code: e.target.value })}
          />
        </Col>
      </Row>

      <Row gutter={24} className="marginTop">
        <Col span={24}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) => setData({ ...data, password: e.target.value })}
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              New password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Col>
      </Row>

      <Row gutter={24} className="marginTop">
        <Col span={24}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) =>
              setData({ ...data, repeatPassword: e.target.value })
            }
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Repeat Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showRepeatPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Col>
      </Row>

      <div className="containerLoginButton">
        <Button
          className="login-form__button"
          loading={loading}
          onClick={changePasswordApi}
        >
          Change Password
        </Button>
      </div>
    </>
  );
}
