import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { KEY_GOOGLE_MAPS } from "../../../config/constants";
import Marker from "../../Marker/Marker";
import { useEffect } from "react";

export function OpenLocationProposal({ dataActive }) {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    setCenter({ lat: 0, lng: 0 });

    if (dataActive) {
      setCenter({
        lat: dataActive?.opportunityPosition?.lat,
        lng: dataActive?.opportunityPosition?.lng,
      });
    }
  }, [dataActive]);

  const defaultProps = {
    center: {
      lat: dataActive?.opportunityPosition?.lat,
      lng: dataActive?.opportunityPosition?.lng,
    },
    zoom: 19,
  };

  if (!dataActive.opportunityPosition) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2rem",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          Does not have a registered position
        </span>
      </div>
    );
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: KEY_GOOGLE_MAPS }}
        center={center}
        defaultZoom={defaultProps.zoom}
      >
        {dataActive?.quotes?.map((quote, index) => {
          if (
            quote?.position &&
            quote.position.lat !== 0 &&
            quote.position.lng !== 0
          ) {
            return (
              <Marker
                key={index}
                lat={quote.position.lat}
                lng={quote.position.lng}
                markerId={quote._id}
                // onClick={onMarkerClick}
              />
            );
          }
          return null;
        })}
      </GoogleMapReact>
    </div>
  );
}
