import React from "react";
import PropsalList from "../../Proposal/PropsalList/PropsalList";

export function OpenModalOpportunieties({ data }) {
  return (
    <PropsalList
      proposal={data}
      title="Proposals"
      pageSize={5}
      addDesactivated={true}
    />
  );
}
