import React, { useState, useEffect, useMemo } from "react";
import Loader from "../../components/Loader/Loader.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { PlusOutlined, FilterOutlined, EyeOutlined } from "@ant-design/icons";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Modal as ModalAntd, Popover, notification } from "antd";
import axios from "axios";
import { BACKEND_URL } from "../../config/config";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { Button } from "antd";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import Modal from "../../components/Modal/Modal";
import { OpenModalAccount } from "../../components/Account";
import moment from "moment";
import { useAuth } from "../../hooks";

export function Accounts({ dataChart, pageSize, title }) {
  // const accounts = localStorage.getItem(ACCOUNTS);
  // const accountsArr = JSON.parse(accounts);
  const [openMySitesData, setMySitesData] = useState([]);
  const [filtering, setFiltering] = React.useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showArrow, setShowArrow] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [arrowAtCenter, setArrowAtCenter] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Función de actualización del tamaño de la ventana
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Suscribirse al evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el efecto cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { user } = useAuth();

  const { confirm } = ModalAntd;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const getData = async () => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/customers/getDashboard?id=` + user?._id;

    const dataDashboard = await axios.get(url).catch((err) => console.log(err));

    if (dataDashboard?.data) {
      const payload = dataDashboard?.data?.data;

      console.log("my sites ------>>>>", payload.mySites);

      setMySitesData(payload.mySites);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log("No dashboard data retrieved");
    }
  };

  useEffect(() => {
    if (user) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  const createAction = () => {};

  const contentPopover = (data) => {
    return (
      <div>
        <p>
          <span style={{ fontWeight: "bold" }}>Start Date: </span>
          {moment(data?.PARCAgreementStartDate).format("MM/DD/YYYY")},{" "}
          <span style={{ fontWeight: "bold" }}>End Date: </span>{" "}
          {moment(data?.PARCAgreementEndDate).format("MM/DD/YYYY")}
        </p>
      </div>
    );
  };

  const text = <span>PARC</span>;

  const mergedArrow = useMemo(() => {
    if (arrowAtCenter)
      return {
        pointAtCenter: true,
      };
    return showArrow;
  }, [showArrow, arrowAtCenter]);

  const columns = [
    {
      title: "Site",
      field: "accountName",
      render: (rowData) => (
        <span> {rowData?.accountName || rowData.companyName}</span>
      ),
    },
    {
      title: "Main Contact",
      field: "mainContact",
      render: (rowData) => (
        <span>{rowData?.mainContact?.fullName || rowData?.mainContact}</span>
      ),
    },
    {
      title: "Street",
      field: "street",
    },
    {
      title: "City",
      field: "city.name",
    },
    {
      title: "State",
      field: "state.name",
    },
    {
      title: "Open Quotes",
      field: "hasQuotes",
      render: (rowData) =>
        rowData.hasQuotes === true ? (
          <CheckCircleOutlineIcon
            style={{ color: "#008f39", marginLeft: "2rem" }}
          />
        ) : (
          <CancelOutlinedIcon
            style={{ color: "#992314", marginLeft: "2rem" }}
          />
        ),
    },
    {
      title: "Has PARC",
      field: "PARCAgreement",
      render: (rowData) =>
        rowData.PARCAgreement === true &&
        new Date(rowData.PARCAgreementEndDate) > today ? (
          <Popover
            placement="bottom"
            title={text}
            content={contentPopover(rowData)}
            arrow={mergedArrow}
          >
            <CheckCircleOutlineIcon
              style={{ color: "#008f39", marginLeft: "2rem" }}
            />
          </Popover>
        ) : (
          <CancelOutlinedIcon
            style={{ color: "#992314", marginLeft: "2rem" }}
          />
        ),
    },
    {
      title: "Open Invoices",
      field: "hasOpenInvoices",
      render: (rowData) =>
        rowData.hasOpenInvoices === true ? (
          <CheckCircleOutlineIcon
            style={{ color: "#008f39", marginLeft: "2rem" }}
          />
        ) : (
          <CancelOutlinedIcon
            style={{ color: "#992314", marginLeft: "2rem" }}
          />
        ),
    },
  ];

  const onCreateButtonClick = () => {};

  const onView = (data) => {
    setIsVisibleModal(true);
    setModalTitle("Account information");
    setModalContent(
      <>
        <OpenModalAccount data={data} />
      </>
    );
  };

  const requestParc = (data) => {
    confirm({
      title: "Request PARC",
      content: `By clicking “yes” below, you are confirming authorization to provide one year of coverage for "${data.accountName}" You will receive documentation on the agreement and be invoiced electronically`,

      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: async () => {
        setIsLoading(true);

        const dataSend = {
          contact: data.mainContact.fullName,
          accountName: data?.accountName || data.companyName,
          account_id: data._id,
          owner: data.owner,
          origin: data.accountName ? "account" : "lead",
          contacID: data.mainContact._id,
        };

        const url = `${BACKEND_URL}/customers/requestPARCAgreement`;
        const response = await axios.post(url, dataSend).catch((err) => {
          setIsLoading(false);
          console.log("error ", err);
        });

        if (response.data) {
          setIsLoading(false);
          notification["success"]({
            message: "Requested PARC successfully",
          });
        }
      },
    });
  };

  const requestSurvey = (data) => {
    confirm({
      title: "Request Survey",
      content: `By clicking “yes” below, you are confirming authorization to have our inspector survey and document the condition of the parking lot(s) at "${data.accountName}". If you have any special requests or wish to meet our inspector onsite, please email info@potholerepair.com`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk: async () => {
        setIsLoading(true);

        const dataSend = {
          contact: data.mainContact.fullName,
          accountName: data?.accountName || data.companyName,
          account_id: data._id,
          owner: data.owner,
          origin: data.accountName ? "account" : "lead",
          contacID: data.mainContact._id,
        };

        const url = `${BACKEND_URL}/customers/requestSurvey`;
        const response = await axios.post(url, dataSend).catch((err) => {
          setIsLoading(false);
          console.log("error ", err);
        });

        if (response.data) {
          setIsLoading(false);
          notification["success"]({
            message: "Survey requested successfully",
          });
        }
      },
    });
  };

  const addDesactivated = true;

  if (user === null || user === undefined) {
    return <Loader />;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="listContainer">
          <ThemeProvider theme={theme}>
            <MaterialTable
              columns={columns}
              className="genericTable"
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <MTableToolbar {...props} />
                    </div>
                    <Button
                      onClick={() => {
                        setFiltering((currentFilter) => !currentFilter);
                      }}
                    >
                      <FilterOutlined />
                    </Button>
                    {addDesactivated ? null : (
                      <Button
                        type="primary"
                        onClick={() => {
                          onCreateButtonClick();
                        }}
                        style={{
                          marginLeft: "6px",
                          backgroundColor: "#00909E",
                          color: "#fff",
                        }}
                      >
                        <PlusOutlined />
                      </Button>
                    )}
                  </div>
                ),
              }}
              data={dataChart ? dataChart : openMySitesData}
              actions={
                dataChart
                  ? []
                  : [
                      (rowData) => ({
                        icon: EyeOutlined,
                        tooltip: "View",
                        onClick: (event, rowData) => {
                          onView(rowData);
                          // ...
                        },
                        // disabled: rowData.view, // Desactivar el botón si el atributo "view" es false
                        // hidden: rowData.view, // Ocultar el botón si el atributo "view" es false
                      }),
                      (rowData) => ({
                        icon: FactCheckOutlinedIcon,
                        tooltip: "Request quote",
                        onClick: (event, rowData) => {
                          requestSurvey(rowData);
                          // Acción que ocurre cuando se hace clic en el botón "View"
                          // ...
                        },
                        // disabled:
                        //   rowData.PARCAgreement &&
                        //   rowData.PARCAgreementEndDate < today, // Desactivar el botón si el atributo "view" es false
                        hidden:
                          (rowData.PARCAgreement &&
                            rowData.PARCAgreementEndDate < today) ||
                          rowData.hasQuotes === true,
                      }),
                      (rowData) => {
                        return {
                          icon: HandshakeOutlinedIcon,
                          tooltip: "Request PARC",
                          onClick: (event, rowData) => {
                            requestParc(rowData);
                            // ...
                          },

                          hidden:
                            (rowData?.PARCAgreement &&
                              new Date(rowData?.PARCAgreementEndDate) >
                                today) ||
                            rowData?.companyName,
                        };
                      },
                    ]
              }
              options={{
                headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                maxBodyHeight: `${windowSize.height - 250}px`,
                minBodyHeight: `${windowSize.height - 250}px`,
                actionsColumnIndex: -1,
                pageSize: pageSize || 20,
                filtering,
                exportMenu: [
                  {
                    label: "Export to PDF",
                    exportFunc: (cols, datas) =>
                      ExportPdf(cols, datas, "Report"),
                  },
                  {
                    label: "Export to CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(cols, datas, "Report"),
                  },
                ],
              }}
              style={{ paddingRight: "20px" }}
              title={title ? title : "My Accounts"}
              onCreateButtonClick={createAction}
              // onDeleteAction={deleteAction}
              // onEditAction={editAction}
            />
          </ThemeProvider>
        </div>
      )}
      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        className={"openModalDocumentsList"}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
