import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./AdminMenu.css";
import { EMAIL_SUPPPORT } from "../../../config/constants";

export function AdminMenu(props) {
  const { menuCollapsed } = props;
  const navigate = useNavigate();
  let location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [classNameTextMenu, setClassNameTextMenu] = useState("");
  const { Sider } = Layout;
  const [current, setCurrent] = useState(location.pathname);

  function sendEmailAction() {
    const mailto = `mailto:${EMAIL_SUPPPORT}`;
    window.open(mailto);
  }
  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === "support") {
      sendEmailAction();
    } else {
      navigate(e.key);
    }
  };

  useEffect(() => {
    if (menuCollapsed === true) {
      setClassNameTextMenu("nav-text-hidden");
    } else {
      setClassNameTextMenu("nav-text");
    }
  }, [menuCollapsed]);

  const items = [
    {
      label: "Dashboard",
      key: location.pathname === "/" ? "/" : "/dashboard",
      icon: <DashboardOutlinedIcon />,
    },
    {
      label: "My Accounts",
      key: "/accounts",
      icon: <ApartmentIcon />,
    },
    {
      label: "My Proposals",
      key: "/proposals",
      icon: <AttachMoneyIcon />,
    },

    {
      label: "My Invoices",
      key: "/invoices",
      icon: <DescriptionIcon />,
    },
    { type: "divider" },
    {
      label: "Support",

      children: [
        { label: "Contact Support", icon: <HelpOutlineIcon />, key: "support" },
      ],
      type: "group",
    },
  ];

  return (
    <Sider className="admin-sider" collapsed={menuCollapsed}>
      <Menu
        className="menu"
        mode="inline"
        onClick={onClick}
        selectedKeys={[current]}
        items={items}
      ></Menu>
    </Sider>
  );
}
