import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader.js";
import { InvoicesList } from "../../components/Invoices/InvoicesList/InvoicesList";
import { useAuth } from "../../hooks";
import { BACKEND_URL } from "../../config/config";
import axios from "axios";

export function Invoices() {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  const { user } = useAuth();

  const getData = async () => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/customers/getInvoicesByCustomerId?id=${user._id}`;

    const dataInvoices = await axios.get(url).catch((err) => console.log(err));

    if (dataInvoices?.data) {
      const payload = dataInvoices?.data?.invoices;

      setInvoices(payload);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log("No  data retrieved");
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <InvoicesList invoices={invoices} title="My Invoices" pageSize={20} />
      )}
    </div>
  );
}
