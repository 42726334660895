import React, { useState, useEffect } from "react";
import moment from "moment";
import { currencyFormatter } from "../../../helpers/currencyHelper";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { esES } from "@mui/material/locale";
import Modal from "../../Modal/Modal";
import { OpenModalInvoice } from "../../Invoices";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import { OpenDocumentList } from "../../Dashboard";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";

export function InvoicesList({ invoices, title, pageSize, chart }) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [status, setStatus] = useState([]);
  const [filtering, setFiltering] = React.useState(false);
  console.log('invoices:',invoices);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Función de actualización del tamaño de la ventana
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Suscribirse al evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el efecto cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  useEffect(() => {
    getStatusesData();
  }, []);

  const getStatusesData = async () => {
    const url = `${BACKEND_URL}/invoicestatuses`;

    const statuses = await axios.get(url).catch((err) => console.log(err));

    if (statuses.data) {
      const payload = statuses.data;

      setStatus(payload);
    }
  };

  const openInvoicescolumns = [
    {
      title: "Site",
      field: "account.accountName",
      render: (rowData) => {
        return rowData?.account?.accountName || ''
      },
    },
    {
      title: "Invoice Number",
      field: "invoiceNo",
    },
    {
      title: "Date of Service",
      field: "dateService",
      render: (rowData) => (
        <span>{moment(rowData?.dateService).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Dispatch Number",
      field: "dispatch",
    },
    {
      title: "Proposal Number",
      field: "opportunity",
      render: (rowData) => {
        return rowData.opportunity?._id
          ? rowData.opportunity._id
          : rowData.opportunity;
      },
    },
    {
      title: "Invoice Date",
      field: "invoiceDate",
      render: (rowData) => (
        <span>{moment(rowData?.invoiceDate).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => (
        <span>{currencyFormatter(rowData?.totalAmountDue)}</span>
      ),
    },
    {
      title: "Status",
      field: "invoiceStatus",
      render: (rowData) => {
        if (rowData?.invoiceStatus?._id) {
          const statusActive = status.filter(
            (status) => status?._id === rowData?.invoiceStatus._id
          );
          return statusActive[0]?.name || "";
        } else {
          const statusActive = status.filter(
            (status) => status?._id === rowData?.invoiceStatus
          );
          return statusActive[0]?.name || "";
        }
      },
    },
  ];

  const onView = (rowData) => {
    setIsVisibleModal(true);
    setModalTitle("Invoice");
    setModalContent(
      <>
        <OpenModalInvoice data={rowData} />
      </>
    );
  };

  const onOpenDocuments = async (rowData) => {
    const url = `${BACKEND_URL}/documentmasters/getByInvoiceId/` + rowData._id;

    const invoiceDocuments = await axios
      .get(url)
      .catch((err) => console.log(err));

    console.log("invoice Documents", invoiceDocuments);
    const dataElement = [];
    if (invoiceDocuments && invoiceDocuments.data.length > 0) {
      invoiceDocuments.data.forEach((document) => {
        dataElement.push({
          documentName: document.documentName,
          documentType: document.documentType.name,
          uploadedDate: moment(document.created).format("MM/DD/YYYY"),
          documentPath: document.documentPath,
        });
      });
      setIsVisibleModal(true);
      setModalTitle("Invoice Documents");
      setModalContent(
        <div style={{ width: "100%" }}>
          <OpenDocumentList data={dataElement} title="" />
        </div>
      );
    } else {
      setIsVisibleModal(true);
      setModalTitle("Invoice Documents");
      setModalContent(
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontWeight: "bold" }}>No documents</span>
        </div>
      );
    }
  };

  const onPay = (data) => {
    const paymentUrl = data?.invoiceQBOPaymentLink
      ? data.invoiceQBOPaymentLink
      : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    window.open(
      paymentUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    );
    // const paymentUrl = rowData?.invoiceQBOPaymentLink
    //   ? rowData.invoiceQBOPaymentLink
    //   : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    // window.open(
    //   paymentUrl,
    //   "_blank",
    //   "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    // );

    // setIsVisibleModal(true);
    // setModalTitle(`Pay Invoice # ${rowData.invoiceNo}`);
    // setModalContent(
    //   <>
    //     <OpenModalPay />
    //   </>
    // );
  };

  return (
    <div>
      <div className="listContainer">
        <ThemeProvider theme={theme}>
          <MaterialTable
            columns={openInvoicescolumns}
            className="genericTable"
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <MTableToolbar {...props} />
                  </div>
                  <Button
                    onClick={() => {
                      setFiltering((currentFilter) => !currentFilter);
                    }}
                  >
                    <FilterOutlined />
                  </Button>
                </div>
              ),
            }}
            data={invoices}
            actions={
              chart
                ? []
                : [
                    (rowData) => ({
                      icon: EyeOutlined,
                      tooltip: "View Detail",
                      onClick: (event, rowData) => {
                        onView(rowData);
                      },
                      hidden: chart,
                    }),
                    (rowData) => ({
                      icon: FileOpenOutlinedIcon,
                      tooltip: "Open Documents",
                      hidden: chart,
                      onClick: (event, rowData) => {
                        onOpenDocuments(rowData);
                        // ...
                      },
                    }),
                    (rowData) => ({
                      icon: PaidOutlinedIcon,
                      tooltip: !rowData.invoiceQBOPaymentLink
                        ? "No payment link available"
                        : "Pay",
                      onClick: (event, rowData) => {
                        onPay(rowData);
                        // ...
                      },
                      disabled:
                        !rowData.invoiceQBOPaymentLink ||
                        rowData.invoiceStatus?._id !== "JCIS",

                      hidden: chart,
                    }),
                  ]
            }
            options={{
              headerStyle: { position: "sticky", top: 0, zIndex: 100 },
              maxBodyHeight: `${windowSize.height - 250}px`,
              minBodyHeight: `${windowSize.height - 250}px`,
              actionsColumnIndex: -1,
              pageSize: pageSize || 20,
              filtering,
              exportMenu: [
                {
                  label: "Export to PDF",
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, "Report"),
                },
                {
                  label: "Export to CSV",
                  exportFunc: (cols, datas) => ExportCsv(cols, datas, "Report"),
                },
              ],
            }}
            style={{ paddingRight: "20px" }}
            title={title}
          />
        </ThemeProvider>
      </div>
      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        className={"openModalDocumentsList"}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
