import { useState, useEffect, createContext } from "react";
import { getAccessTokenApi, logout } from "../api/auth";
import { USER } from "../config/constants";
// import { User, Auth } from "../api";
// import { hasExpiredToken } from "../utils";

// const userController = new User();
// const authController = new Auth();

export const AuthContext = createContext();

export function AuthProvider(props) {
  const { children } = props;
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [laoding, setLaoding] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem(USER));

  useEffect(() => {
    (async () => {
      const accessToken = getAccessTokenApi();

      if (!accessToken || !currentUser) {
        logoutUser();
        setLaoding(false);
        return;
      }
      if (currentUser) {
        await login(currentUser, accessToken);
      }
      // }
      setLaoding(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (user, hashToken) => {
    try {
      setUser(user);
      setToken(hashToken);
    } catch (error) {
      console.error(error);
    }
  };

  const logoutUser = () => {
    setUser(null);
    setToken(null);
    logout();
  };

  const data = {
    accessToken: token,
    user,
    login,
    logout,
  };

  if (laoding) return null;

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}
