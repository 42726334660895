import React, { useEffect, useState } from "react";
import { DoughnutChart, Indicator } from "../../components/Charts";
import "./Dashboard.css";
import moment from "moment/moment";
import { BACKEND_URL } from "../../config/config";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import Modal from "../../components/Modal/Modal";
import { OpenDocumentList } from "../../components/Dashboard";
import { currencyFormatter } from "../../helpers/currencyHelper";
import OpenContentApprove from "../../components/Proposal/OpenContentApprove/OpenContentApprove";
import { OpenModalProposal } from "../../components/Proposal/OpenModalProposal/OpenModalProposal";
import { useAuth } from "../../hooks";
import { OpenModalInvoice } from "../../components/Invoices";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { esES } from "@mui/material/locale";
import { Button } from "antd";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { FilterOutlined, EyeOutlined } from "@ant-design/icons";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [indicators, setIndicators] = useState([]);
  const [parcAgreementDataSet, setParcAgreementDataSet] = useState([]);
  const [proposalsByStatusDataSet, setProposalsByStatusDataSet] = useState([]);
  const [invoicesByStatusDataSet, setInvoicesByStatusDataSet] = useState([]);
  const [openProposalsData, setOpenProposalsDataset] = useState([]);
  const [openInvoicesData, setOpenInvoicesDataset] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);
  const [filtering, setFiltering] = React.useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const { user } = useAuth();

  const getDataDashboard = async (usuario) => {
    setIsLoading(true);

    if (usuario?._id) {
      const url = `${BACKEND_URL}/customers/getDashboard?id=` + usuario._id;

      const dataDashboard = await axios
        .get(url)
        .catch((err) => console.log(err));

      if (dataDashboard?.data) {
        const payload = dataDashboard?.data?.data;

        setIndicators(payload?.indicators);
        console.log("data Dashboard", payload);
        setOpenProposalsDataset(payload?.openProposals);
        setOpenInvoicesDataset(payload?.openInvoices);

        const parcData = getParcData(payload);
        setParcAgreementDataSet(parcData);

        const proposalsByStatusData = getProposalsByStatusData(payload);
        setProposalsByStatusDataSet(proposalsByStatusData);

        const invoicesByStatusData = getInovoicesByStatusData(payload);
        setInvoicesByStatusDataSet(invoicesByStatusData);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("No dashboard data retrieved");
      }
    }
  };

  const getDataRefresh = async (usuario) => {
    setLoadingTable(true);
    if (usuario?._id) {
      const url = `${BACKEND_URL}/customers/getDashboard?id=` + usuario._id;

      const dataDashboard = await axios.get(url).catch((err) => {
        setLoadingTable(false);
        console.log(err);
      });

      if (dataDashboard?.data) {
        const payload = dataDashboard?.data?.data;

        setIndicators(payload?.indicators);
        setOpenProposalsDataset(payload?.openProposals);
        setOpenInvoicesDataset(payload?.openInvoices);

        const parcData = getParcData(payload);
        setParcAgreementDataSet(parcData);

        const proposalsByStatusData = getProposalsByStatusData(payload);
        setProposalsByStatusDataSet(proposalsByStatusData);

        const invoicesByStatusData = getInovoicesByStatusData(payload);
        setInvoicesByStatusDataSet(invoicesByStatusData);
        setLoadingTable(false);
      } else {
        console.log("No dashboard data retrieved");
      }
    }
  };

  const handleRefreshChange = () => {
    getDataRefresh(user);
  };

  useEffect(() => {
    if (user) {
      getDataDashboard(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, reload]);

  const openProposalscolumns = [
    {
      title: "Name",
      field: "opportunityName",
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span>{rowData.opportunityName}</span>
          <span>
            {rowData.approvedByCustomer ? (
              <CheckCircleOutlineIcon
                style={{ paddingLeft: "5px", color: "#008f39" }}
              />
            ) : (
              ""
            )}
          </span>
        </div>
      ),
    },
    {
      title: "Requested Date",
      field: "requestedDate",
      render: (rowData) => (
        <span>{moment(rowData?.requestedDate).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Valid Until",
      field: "validUntil",
      render: (rowData) => (
        <span>{moment(rowData?.validUntil).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Quotes",
      field: "quoteCount",
    },
    {
      title: "TR's",
      field: "numberTRs",
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => <span>{currencyFormatter(rowData?.amount)}</span>,
    },
  ];

  const openInvoicescolumns = [
    {
      title: "Site",
      field: "account.accountName",
    },
    {
      title: "Invoice Number",
      field: "invoiceNo",
    },
    {
      title: "Date of Service",
      field: "dateService",
      render: (rowData) => (
        <span>{moment(rowData.dateService).format("MM/DD/YYYY")}</span>
      ),
    },

    {
      title: "Dispatch Number",
      field: "dispatch",
    },
    {
      title: "Proposal Number",
      field: "opportunity._id",
    },

    {
      title: "Invoice Date",
      field: "invoiceDate",
      render: (rowData) => (
        <span>{moment(rowData.invoiceDate).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Status",
      field: "invoiceStatus.name",
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => (
        <span>{currencyFormatter(rowData?.totalAmountDue)}</span>
      ),
    },
  ];

  const getProposalById = async (proposal) => {
    const { data } = await axios
      .get(`${BACKEND_URL}/opportunities/${proposal._id}`)
      .catch((err) => console.log(err));

    if (data) {
      return data;
    }
  };

  const showProposalDetail = async (data) => {
    const proposalById = await getProposalById(data);

    setIsVisibleModal(true);
    setModalTitle(`Proposal detail # ${data.proposalNo}`);
    setModalContent(
      <>
        <OpenModalProposal
          proposal={proposalById}
          ApproveOpportunity={ApproveOpportunity}
          handleRefreshChange={handleRefreshChange}
        />
      </>
    );
  };

  const showInvoiceDetail = (data) => {
    console.log("data dashboard invoice", data);
    setIsVisibleModal(true);
    setModalTitle("Invoice");
    setModalContent(
      <>
        <OpenModalInvoice data={data} />
      </>
    );
  };

  const showProposalDocumentsList = async (data) => {
    const url = `${BACKEND_URL}/documentmasters/getByOpportunityId/` + data._id;

    const opportunityDocuments = await axios
      .get(url)
      .catch((err) => console.log(err));

    const dataElement = [];
    if (opportunityDocuments && opportunityDocuments.data.length > 0) {
      opportunityDocuments.data.forEach((document) => {
        dataElement.push({
          documentName: document.documentName,
          documentType: document.documentType.name,
          uploadedDate: moment(document.created).format("MM/DD/YYYY"),
          documentPath: document.documentPath,
        });
      });
      setIsVisibleModal(true);
      setModalTitle("Proposal Documents");
      setModalContent(
        <div style={{ width: "100%" }}>
          <OpenDocumentList data={dataElement} title="" />
        </div>
      );
    } else {
      setIsVisibleModal(true);
      setModalTitle("Proposal Documents");
      setModalContent(
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontWeight: "bold" }}>No documents</span>
        </div>
      );
    }
  };

  const showInvoiceDocumentsList = async (data) => {
    const url = `${BACKEND_URL}/documentmasters/getByInvoiceId/` + data._id;

    const opportunityDocuments = await axios
      .get(url)
      .catch((err) => console.log(err));

    const dataElement = [];
    if (opportunityDocuments && opportunityDocuments.data.length > 0) {
      opportunityDocuments.data.forEach((document) => {
        dataElement.push({
          documentName: document.documentName,
          documentType: document.documentType.name,
          uploadedDate: moment(document.created).format("MM/DD/YYYY"),
          documentPath: document.documentPath,
        });
      });
      setIsVisibleModal(true);
      setModalTitle("Proposal Documents");
      setModalContent(
        <div style={{ width: "100%" }}>
          <OpenDocumentList data={dataElement} title="" />
        </div>
      );
    }
  };

  const ApproveOpportunity = (data) => {
    setIsVisibleModal(true);
    setModalTitle("Approve this proposal");
    setModalContent(
      <OpenContentApprove
        setIsVisibleModal={setIsVisibleModal}
        proposal={data}
        reload={reload}
        setReload={setReload}
        handleRefreshChange={handleRefreshChange}
      />
    );
  };

  const pay = (data) => {
    const paymentUrl = data?.invoiceQBOPaymentLink
      ? data.invoiceQBOPaymentLink
      : "https://connect.intuit.com/portal/app/CommerceNetwork/view/scs-v1-09407418d0d44b2e936d[…]0a15459a439fb965e1442efec100?locale=en_US&cta=v3invoicelink"; // URL de la página de pago
    window.open(
      paymentUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
    );
    // setIsVisibleModal(true);
    // setModalTitle(`Pay Invoice # ${data.invoiceNo}`);
    // setModalContent(
    //   <>
    //     <OpenModalPay />
    //   </>
    // );
  };
  const colors = [
    "#F07D17",
    "rgb(255 49 106)",
    "#12CD56",
    "#8D10DA",
    "rgb(37 107 233)",
  ];

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  if (user === null || user === undefined) {
    return <Loader />;
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {indicators?.map((i, index) => (
          <Indicator
            key={index}
            value={i?.value}
            title={i?.key}
            color={colors[index % colors.length]}
          />
        ))}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          height: "20rem",
          paddingTop: "2rem",
          background: "#E1E1E1",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        {parcAgreementDataSet.length <= 0 ? (
          <span>No data found</span>
        ) : (
          <>
            <DoughnutChart
              title="PARC Agreement"
              dataSet={parcAgreementDataSet}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
            />
            <DoughnutChart
              title={"Proposals (By Status)"}
              dataSet={proposalsByStatusDataSet}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
            />

            <DoughnutChart
              title={"Invoices (By Status)"}
              dataSet={invoicesByStatusDataSet}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
            />
          </>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <div className="listContainer">
          <ThemeProvider theme={theme}>
            <MaterialTable
              isLoading={loadingTable}
              columns={openProposalscolumns}
              className="genericTable"
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <MTableToolbar {...props} />
                    </div>
                    <Button
                      onClick={() => {
                        setFiltering((currentFilter) => !currentFilter);
                      }}
                    >
                      <FilterOutlined />
                    </Button>
                  </div>
                ),
              }}
              data={openProposalsData}
              actions={[
                (rowData) => ({
                  icon: EyeOutlined,
                  tooltip: "View Detail",
                  onClick: (event, rowData) => {
                    showProposalDetail(rowData);
                  },
                }),
                (rowData) => ({
                  icon: FileOpenOutlinedIcon,
                  tooltip: "Open Documents",
                  onClick: (event, rowData) => {
                    showProposalDocumentsList(rowData);
                    // ...
                  },
                }),
                (rowData) => ({
                  icon: TaskAltOutlinedIcon,
                  tooltip: "Approve Proposals",
                  onClick: (event, rowData) => {
                    ApproveOpportunity(rowData);
                    // ...
                  },

                  hidden:
                    rowData.approvedByCustomer === true ||
                    rowData.status._id !== "PROPOSAL" ||
                    rowData.amount <= 0,
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5,
                filtering,
                exportMenu: [
                  {
                    label: "Export to PDF",
                    exportFunc: (cols, datas) =>
                      ExportPdf(cols, datas, "Report"),
                  },
                  {
                    label: "Export to CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(cols, datas, "Report"),
                  },
                ],
              }}
              style={{ paddingRight: "20px" }}
              title={"Open Proposals"}
            />
          </ThemeProvider>
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <div className="listContainer">
          <ThemeProvider theme={theme}>
            <MaterialTable
              isLoading={loadingTable}
              columns={openInvoicescolumns}
              className="genericTable"
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <MTableToolbar {...props} />
                    </div>
                    <Button
                      onClick={() => {
                        setFiltering((currentFilter) => !currentFilter);
                      }}
                    >
                      <FilterOutlined />
                    </Button>
                  </div>
                ),
              }}
              data={openInvoicesData}
              actions={[
                (rowData) => ({
                  icon: EyeOutlined,
                  tooltip: "View Detail",
                  onClick: (event, rowData) => {
                    showInvoiceDetail(rowData);
                  },
                }),
                (rowData) => ({
                  icon: FileOpenOutlinedIcon,
                  tooltip: "Open Documents",
                  onClick: (event, rowData) => {
                    showInvoiceDocumentsList(rowData);
                    // ...
                  },
                }),
                (rowData) => ({
                  icon: PaidOutlinedIcon,
                  tooltip: !rowData.invoiceQBOPaymentLink
                    ? "No payment link available"
                    : "Pay",
                  onClick: (event, rowData) => {
                    pay(rowData);
                    // ...
                  },

                  disabled:
                    !rowData.invoiceQBOPaymentLink ||
                    rowData.invoiceStatus?._id !== "JCIS",
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5,
                filtering,
                exportMenu: [
                  {
                    label: "Export to PDF",
                    exportFunc: (cols, datas) =>
                      ExportPdf(cols, datas, "Report"),
                  },
                  {
                    label: "Export to CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(cols, datas, "Report"),
                  },
                ],
              }}
              style={{ paddingRight: "20px" }}
              title={"Open Invoices"}
            />
          </ThemeProvider>
        </div>
      </div>

      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        className={
          modalTitle === "Approve this proposal"
            ? null
            : "openModalDocumentsList"
        }
      >
        {modalContent}
      </Modal>
    </>
  );

  function getParcData(payload) {
    let withOutPARC = 0;
    let withPARC = 0;
    let withDueSoonPARC = 0;

    let dataArray = [];
    let dataRender = [];
    let dataWithOutPARC = [];
    let dataWithPARC = [];
    let dataWithDueSoonPARC = [];

    payload?.mySites?.forEach((site) => {
      if (site.PARCAgreement) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const thirtyDaysAfter = new Date();
        thirtyDaysAfter.setDate(thirtyDaysAfter.getDate() + 30);

        const isWithinRange =
          new Date(site.PARCAgreementEndDate) >= today &&
          new Date(site.PARCAgreementEndDate) <= thirtyDaysAfter;
        const isGreaterThanRange =
          new Date(site.PARCAgreementEndDate) > thirtyDaysAfter;

        if (site.PARCAgreement && isWithinRange) {
          withDueSoonPARC += 1;
          dataWithDueSoonPARC.push(site);
        }
        if (isGreaterThanRange) {
          withPARC += 1;
          dataWithPARC.push(site);
        }
        if (!isWithinRange && !isGreaterThanRange) {
          withOutPARC += 1;
          dataWithOutPARC.push(site);
        }
      } else {
        withOutPARC += 1;
        dataWithOutPARC.push(site);
      }
    });

    const colorsArray = getRandomRGB(3);

    const labelsArr = ["Not PARC"];
    dataArray.push(withOutPARC);
    dataRender.push(dataWithOutPARC);

    if (withPARC > 0) {
      labelsArr.push("PARC");
      dataArray.push(withPARC);
      dataRender.push(dataWithPARC);
    }

    if (withDueSoonPARC > 0) {
      labelsArr.push("PARC due soon");
      dataArray.push(withDueSoonPARC);
      dataRender.push(dataWithDueSoonPARC);
    }

    const data = {
      labels: labelsArr,

      datasets: [
        {
          data: dataArray,
          backgroundColor: colorsArray,
          borderColor: ["#fff"],
          borderWidth: 1,
          dataRender: dataRender,
        },
      ],
    };

    return data;
  }

  function getProposalsByStatusData(payload) {
    let labelsArr = [];
    let valuesArr = [];
    let dataRender = [];
    console.log("data,", payload);

    payload?.proposalByStatusSummary?.forEach(function (status) {
      if (status.value > 0) {
        labelsArr.push(status.key);
        valuesArr.push(status.value);
        dataRender.push(status.data);
      }
    });

    const colorsArray = getRandomRGB(valuesArr.length);
    const data = {
      labels: labelsArr,

      datasets: [
        {
          data: valuesArr,
          backgroundColor: colorsArray,
          borderColor: ["#fff"],
          borderWidth: 1,
          dataRender: dataRender,
        },
      ],
    };

    return data;
  }

  function getInovoicesByStatusData(payload) {
    let labelsArr = [];
    let valuesArr = [];
    let dataRender = [];

    payload?.allInvoicesByStatus?.forEach(function (item) {
      const total = item.invoices.length;
      labelsArr.push(item.status);
      valuesArr.push(total);
      dataRender.push(item.invoices);
    });

    const colorsArray = getRandomRGB(valuesArr.length);
    const data = {
      labels: labelsArr,

      datasets: [
        {
          data: valuesArr,
          backgroundColor: colorsArray,
          borderColor: ["#fff"],
          borderWidth: 1,
          dataRender: dataRender,
        },
      ],
    };

    return data;
  }

  function getRandomRGB(elementsNumber) {
    const colors = [];
    for (let i = 0; i < elementsNumber; i++) {
      const color = genertateRandomColor();
      colors.push(color);
    }
    return colors;
  }

  function genertateRandomColor() {
    // Generar componentes RGB en el rango adecuado para colores pasteles
    const r = Math.floor(Math.random() * 101) + 155; // Rango: 155-255
    const g = Math.floor(Math.random() * 101) + 155; // Rango: 155-255
    const b = Math.floor(Math.random() * 101) + 155; // Rango: 155-255

    return `rgb(${r}, ${g}, ${b})`;
  }
}
