import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import StartIcon from "@mui/icons-material/Start";
import moment from "moment";

export function OpenModalJobTimeLine({ proposal }) {
  const getIconJob = (job) => {
    switch (job?.stage) {
      case "CREATED":
        return <AddCircleOutlineOutlinedIcon style={{ color: "#008f39" }} />;
      case "SCHEDULED":
        return <CalendarTodayOutlinedIcon style={{ color: "blue" }} />;
      case "COMPLETED":
        return <CheckCircleOutlineOutlinedIcon style={{ color: "#008f39" }} />;
      case "START":
        return <StartIcon style={{ color: "#E9D502" }} />;
      case "RESCHEDULED":
        return <RestartAltOutlinedIcon style={{ color: "#D0342C" }} />;
      default:
        return <AddCircleOutlineOutlinedIcon style={{ color: "#008f39" }} />;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {proposal.jobTimeline.length > 0 && (
        <Timeline position="alternate">
          {proposal.jobTimeline.map((job, index) => (
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {moment(job.date).format("MM/DD/YYYY")}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined">{getIconJob(job)}</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {job.title}
                </Typography>
                <Typography>{job?.message}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}

      {proposal.jobTimeline.length <= 0 && (
        <>
          <span> Not jobs </span>
        </>
      )}
    </div>
  );
}
