import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import Loader from "../../Loader/Loader";
import { Divider, TextField } from "@mui/material";

import { Col, Row } from "antd";
import "./OpenModalView.css";
import { THEME_COLORS } from "../../../config/constants";

export function OpenModalView({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataActive, setDataActive] = useState();

  const getInformationAccount = async () => {
    setIsLoading(true);

    let url;
    if (data.companyName) {
      url = `${BACKEND_URL}/leads/${data._id}`;
    } else {
      url = `${BACKEND_URL}/accounts/${data._id}`;
    }
    const dataAccount = await axios.get(url).catch((err) => console.log(err));

    if (dataAccount?.data) {
      const payload = dataAccount.data;

      console.log("open modal view account ", payload);

      setDataActive(payload);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log("No dashboard data retrieved");
    }
  };

  useEffect(() => {
    getInformationAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Owner"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.owner?.displayName}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Company/Site"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.accountName || dataActive?.companyName}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Mgmt Company"
                variant="outlined"
                fullWidth
                size="small"
                value={
                  dataActive?.parentAccount?.accountName ||
                  dataActive?.managementAccount?.accountName
                }
              />
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Main Phone"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.mainPhone}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Fax"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.fax}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Region"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.region?.description}
              />
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Website"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.website}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Main Contact"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={`${dataActive?.mainContact?.firstName} ${dataActive?.mainContact?.lastName}`}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Acc. Payable"
                variant="outlined"
                fullWidth
                size="small"
                // value={dataActive?.mainContact?.fullName}
              />
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="compliance Vendor"
                variant="outlined"
                fullWidth
                size="small"
                // value={dataActive?.source?.description}
              />
            </Col>
          </Row>

          <Divider
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              color: THEME_COLORS.NARANJA,
            }}
          >
            Address Information
          </Divider>
          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="State"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.state?.name}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="City"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.city?.name}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Street"
                variant="outlined"
                fullWidth
                size="small"
                required
                value={dataActive?.street}
              />
            </Col>
          </Row>
          <Divider
            style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              color: THEME_COLORS.NARANJA,
            }}
          >
            Billing Address Information
          </Divider>

          <Row gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="State"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.billingState?.name}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="City"
                variant="outlined"
                fullWidth
                required
                size="small"
                value={dataActive?.billingCity?.name}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <TextField
                id="standard-basic"
                label="Street"
                variant="outlined"
                fullWidth
                size="small"
                required
                value={dataActive?.billingStreet}
              />
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <TextField
                id="standard-basic"
                label="Suite"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.suite}
              />
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <TextField
                id="standard-basic"
                label="Appartament"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.apartment}
              />
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <TextField
                id="standard-basic"
                label="Bill Box # / PO"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.billBoxNumber}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <TextField
                id="standard-basic"
                label="Electronic Email Bill"
                variant="outlined"
                fullWidth
                size="small"
                value={dataActive?.electronicEmailBill}
              />
            </Col>
          </Row>

          <Row gutter={24} className="rowInputs">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <TextField
                id="outlined-basic"
                label="Special Billing Instructions"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                value={dataActive?.specialBillingInstructions}
                // onChange={(e) =>
                //   setSchoolData({
                //     ...schoolData,
                //     description: e.target.value,
                //   })
                // }
                // value={schoolData?.description}
                minRows={3}
              />
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <TextField
                id="outlined-basic"
                label="Special Billing Address"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                value={dataActive?.specialBillingAddress}
                // onChange={(e) =>
                //   setSchoolData({
                //     ...schoolData,
                //     description: e.target.value,
                //   })
                // }
                // value={schoolData?.description}
                minRows={3}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
