import { BrowserRouter } from "react-router-dom";
import { RoutesWeb } from "./routes";

import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <RoutesWeb></RoutesWeb>
      </BrowserRouter>
    </AuthProvider>
  );
}

// prueba

export default App;
