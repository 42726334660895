import React from "react";

import "./ForgotPassword.css";
import { Button, Col, Row, notification } from "antd";
import {
  InputLabel,
  OutlinedInput,
  TextField,
  FormControl,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { useState } from "react";
// import { FormControl, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { BACKEND_URL } from "../../config/config";
import axios from "axios";

export default function ForgotPassword({ setIsVisibleModal, isVisibleModal }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  // const [showPassword, setShowPassword] = React.useState(false);
  // const [showRepeatPassword, setShowRepeatPassword] = React.useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleClickShowRepeatPassword = () =>
  //   setShowRepeatPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const forgotPassword = async () => {
    setLoading(true);
    if (!data.email) {
      notification["error"]({
        message:
          "Please enter your email address before submitting the request",
      });
      setLoading(false);
      return;
    }

    // if (data.password !== data.repeatPassword) {
    //   notification["error"]({
    //     message:
    //       "The provided passwords do not match. Please verify and try again.",
    //   });
    //   setLoading(false);
    //   return;
    // }

    try {
      const url = `${BACKEND_URL}/customers/forgotPassword`;

      const dataSendApi = {
        email: data.email,
      };

      const response = await axios.post(url, dataSendApi);

      if (response?.data?.code === 0) {
        notification["success"]({
          message: "Request sent successfully",
        });
        setLoading(false);
        setData({});
        setIsVisibleModal(false);
        return;
      }

      // if (response.data) {
      //   const urlChangePassword = `${BACKEND_URL}/customers/changePassword `;
      //   const dataSendChangePassword = {
      //     email: data.email,
      //     password: data.password,
      //   };
      //   const responseChangePassword = await axios.post(
      //     urlChangePassword,
      //     dataSendChangePassword
      //   );

      //   if (responseChangePassword.data) {
      //     notification["success"]({
      //       message:
      //         "The required request has been sent to the support team. We will contact you shortly.",
      //     });
      //     setIsVisibleModal(false);
      //     setLoading(false);
      //   }
      // }
    } catch (e) {
      console.log("error catch forgotPassword", e);
      notification["err"]({
        message:
          "We apologize, but there seems to be a server issue at the moment. Please try again later. Thank you for your understanding.",
      });
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <Row gutter={24} className="marginTop">
        <Col span={24}>
          <div style={{ paddingBottom: "1.5rem" }}>
            Please enter the email address associated with your account. We will
            send you instructions on how to reset your password.
          </div>
          <TextField
            id="standard-basic"
            label="Email"
            variant="outlined"
            fullWidth
            required
            size="small"
            value={data?.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </Col>
      </Row>

      {/* <Row gutter={24} className="marginTop">
        <Col span={24}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) => setData({ ...data, password: e.target.value })}
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              New password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Col>
      </Row>

      <Row gutter={24} className="marginTop">
        <Col span={24}>
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) =>
              setData({ ...data, repeatPassword: e.target.value })
            }
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Repeat Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showRepeatPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Col>
      </Row> */}

      <div className="containerLoginButton">
        <Button
          className="login-form__button"
          loading={loading}
          onClick={forgotPassword}
        >
          Reset Request
        </Button>
      </div>
    </>
  );
}
