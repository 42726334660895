import React, { useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { InvoicesList } from "../Invoices";
import { Accounts } from "../../pages/Accounts/Accounts";
import PropsalList from "../Proposal/PropsalList/PropsalList";

export function DoughnutChart({
  title,
  dataSet,
  setIsVisibleModal,
  setModalTitle,
  setModalContent,
}) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: true,
        fontSize: 20,
        text: title,
      },
    },
  };
  const chartRef = useRef();

  const onClick = (event) => {
    const element = getElementAtEvent(chartRef.current, event);
    const index = element[0]?.index;
    const datasetIndex = element[0]?.datasetIndex;
    // const value = dataSet?.datasets[datasetIndex]?.data[index];
    const DataTable = dataSet?.datasets[datasetIndex]?.dataRender[index];

    setIsVisibleModal(true);
    setModalTitle(`${dataSet?.labels[index]}` || "");
    setModalContent(
      <>
        {title === "Invoices (By Status)" && (
          <InvoicesList
            invoices={DataTable}
            title={title}
            pageSize={20}
            chart={true}
          />
        )}
        {title === "PARC Agreement" && (
          <Accounts
            dataChart={DataTable}
            pageSize={20}
            title={"PARC Agreement"}
          />
        )}
        {title === "Proposals (By Status)" && (
          <PropsalList
            proposal={DataTable}
            title={"Proposals"}
            pageSize={20}
            dataChart={true}
          />
        )}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span style={{ fontWeight: "bold" }}>{title}</span>
      <Doughnut
        data={dataSet}
        options={options}
        redraw={true}
        onClick={onClick}
        ref={chartRef}
      />
    </div>
  );
}
