import { BACKEND_URL } from "../config/config";
import { HASH_TOKEN } from "../config/constants";
// import jwtDecode from "jwt-decode";

export function getAccessTokenApi() {
  const accessToken = localStorage.getItem(HASH_TOKEN);

  if (!accessToken || accessToken === "null") {
    return null;
  }

  //   return willExpireToken(accessToken) ? null : accessToken;
  return accessToken;
}

// export function getRefreshTokenApi() {
//   const refreshToken = localStorage.getItem(REFRESH_TOKEN);

//   if (!refreshToken || refreshToken === "null") {
//     return null;
//   }

//   return willExpireToken(refreshToken) ? null : refreshToken;
// }

// export function refreshAccessTokenApi(refreshToken) {
//   const url = `${BACKEND_URL}/refresh-access-token`;
//   const bodyObj = {
//     refreshToken: refreshToken,
//   };
//   const params = {
//     method: "POST",
//     body: JSON.stringify(bodyObj),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };

//   fetch(url, params)
//     .then((response) => {
//       if (response.status !== 200) {
//         return null;
//       }
//       return response.json();
//     })
//     .then((result) => {
//       if (!result) {
//         logout();
//       } else {
//         const { accessToken, refreshToken } = result;

//         localStorage.setItem(ACCESS_TOKEN, accessToken);
//         localStorage.setItem(REFRESH_TOKEN, refreshToken);
//       }
//     });
// }

// export function getUser(id) {
//   const url = `${BACKEND_URL}/user/${id}`;
//   const params = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };

//   return fetch(url, params)
//     .then((response) => {
//       return response.json();
//     })
//     .then((result) => {
//       return result;
//     })
//     .catch((err) => {
//       return err.message;
//     });
// }

export function signInApi(data) {
  const url = `${BACKEND_URL}/customers/authenticate`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function requestAccessApi(data) {
  const url = `${BACKEND_URL}/customers/createHashToken`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
export function logout() {
  localStorage.removeItem(HASH_TOKEN);
}
