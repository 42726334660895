import React, { useState, useEffect } from "react";
import { USER } from "../../config/constants";
import Loader from "../../components/Loader/Loader.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import { FilterOutlined } from "@ant-design/icons";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment/moment";
import axios from "axios";
import { BACKEND_URL } from "../../config/config";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import ExportPdf from "@material-table/exporters/pdf";
import ExportCsv from "@material-table/exporters/csv";
import { Button } from "antd";
import Modal from "../../components/Modal/Modal";
import { OpenModalView } from "../../components/Account";

const currentUser = JSON.parse(localStorage.getItem(USER));

export function Notifications() {
  const [notificationsData, setNotificationsData] = useState([]);
  const [filtering, setFiltering] = React.useState(false);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUnreadNotifications = async () => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/customerNotifications?id=${currentUser._id}&show=NOREADED`; // + currentUser._id;

    const notificationsData = await axios
      .get(url)
      .catch((err) => console.log(err));

    if (notificationsData?.data) {
      const payload = notificationsData?.data?.notifications || [];
      console.log("Notifications data ---->", payload);
      setNotificationsData(payload);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.log("No dashboard data retrieved");
    }
  };

  // const getAllNotifications = async () => {
  //   setIsLoading(true);
  //   const url = `${BACKEND_URL}/customerNotifications?id=` + currentUser._id;

  //   const notificationsData = await axios
  //     .get(url)
  //     .catch((err) => console.log(err));

  //   if (notificationsData?.data) {
  //     const payload = notificationsData?.data?.notifications || [];
  //     console.log("Notifications data ---->", payload);
  //     setNotificationsData(payload);

  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //     console.log("No dashboard data retrieved");
  //   }
  // };

  useEffect(() => {
    getUnreadNotifications();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    esES
  );

  const createAction = () => {};

  const columns = [
    {
      title: "Subject",
      field: "subject",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Company",
      field: "lead",
      render: (rowData) => <span>{rowData?.account.accountName}</span>,
    },
    {
      title: "Created",
      field: "created",
      render: (rowData) => (
        <span>{moment(rowData?.created).format("MM/DD/YYYY")}</span>
      ),
    },
  ];

  const onMarkAsReadClick = async (event, rowData) => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/updateCustomerNotification`;

    rowData.readed = true;

    // eslint-disable-next-line no-unused-vars
    const updatedNotification = await axios
      .put(url, rowData)
      .catch((err) => console.log(err));

    setIsLoading(false);

    getUnreadNotifications();
  };

  const onDeleteNotification = async (event, rowData) => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/deleteCustomerNotification?id=` + rowData._id;

    // eslint-disable-next-line no-unused-vars
    const notificationsData = await axios
      .post(url)
      .catch((err) => console.log(err));
    setIsLoading(false);
    getUnreadNotifications();
  };

  const onView = (data) => {
    setIsVisibleModal(true);
    setModalTitle("Child / Site Account Information");
    setModalContent(
      <>
        <OpenModalView data={data} />
      </>
    );
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className="listContainer">
          <ThemeProvider theme={theme}>
            <MaterialTable
              columns={columns}
              className="genericTable"
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <MTableToolbar {...props} />
                    </div>
                    <Button
                      onClick={() => {
                        setFiltering((currentFilter) => !currentFilter);
                      }}
                    >
                      <FilterOutlined />
                    </Button>
                  </div>
                ),
              }}
              data={notificationsData}
              actions={[
                (rowData) => ({
                  icon: "visibility",
                  tooltip: "View",
                  onClick: (event, rowData) => {
                    onView(rowData);
                  },
                }),
                (rowData) => ({
                  icon: MarkEmailReadIcon,
                  tooltip: "Mark as read",
                  onClick: onMarkAsReadClick,
                }),

                (rowData) => ({
                  icon: DeleteOutlinedIcon,
                  tooltip: "Delete",
                  onClick: onDeleteNotification,
                }),
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 10,
                filtering,
                exportMenu: [
                  {
                    label: "Export tp PDF",
                    exportFunc: (cols, datas) =>
                      ExportPdf(cols, datas, "reporte"),
                  },
                  {
                    label: "Export to CSV",
                    exportFunc: (cols, datas) =>
                      ExportCsv(cols, datas, "reporte"),
                  },
                ],
              }}
              style={{ paddingRight: "20px" }}
              title="Notifications"
              onCreateButtonClick={createAction}
            />
          </ThemeProvider>
        </div>
      )}
      <Modal
        title={modalTitle}
        open={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        className={"openModalDocumentsList"}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
