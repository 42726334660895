import React from "react";
import {
  Auth,
  Dashboard,
  Error404,
  Accounts,
  Proposals,
  Invoices,
  Reports,
  Notifications,
} from "../pages";
import { Routes, Route } from "react-router-dom";
import { LayoutAdmin } from "../layout";
import { useAuth } from "../hooks";

// const user = null;

export function RoutesWeb() {
  const { user } = useAuth();

  const loadLayout = (Layout, Page) => (
    <Layout>
      <Page />
    </Layout>
  );

  return (
    <Routes>
      {!user ? (
        <Route path="/*" element={<Auth />} />
      ) : (
        <>
          {["/", "/dashboard"].map((path) => (
            <Route
              key={path}
              path={path}
              element={loadLayout(LayoutAdmin, Dashboard)}
            />
          ))}
          <Route path="/accounts" element={loadLayout(LayoutAdmin, Accounts)} />
          <Route
            path="/proposals"
            element={loadLayout(LayoutAdmin, Proposals)}
          />
          <Route path="/invoices" element={loadLayout(LayoutAdmin, Invoices)} />

          <Route path="/reports" element={loadLayout(LayoutAdmin, Reports)} />

          <Route
            path="/notifications"
            element={loadLayout(LayoutAdmin, Notifications)}
          />

          <Route path="*" element={loadLayout(LayoutAdmin, Error404)} />
        </>
      )}
    </Routes>
  );
}
