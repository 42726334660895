import React, { useState, useEffect } from "react";
import { Avatar, Badge, Button, Popover } from "antd";

import {
  MenuUnfoldOutlined,
  PoweroffOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../hooks";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import "./MenuTop.css";
import Logo from "../../../assets/logo.png";
import NoAvatar from "../../../assets/no-avatar.png";
import { DISPLAYNAME, EMAIL, USER } from "../../../config/constants";
import { BACKEND_URL } from "../../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export function MenuTop(props) {
  const { menuCollapsed, setMenuCollapsed } = props;
  const { logout } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = JSON.parse(localStorage.getItem(USER));
  const navigate = useNavigate();

  const logoutUser = () => {
    logout();
    window.location.href = "/";
  };

  const avatar = null;
  const email = localStorage.getItem(EMAIL);
  const fullName = localStorage.getItem(DISPLAYNAME);

  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);

  const navigateToNotifications = () => {
    navigate("/notifications");
  };

  const getNotifications = async () => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/customerNotifications?id=${currentUser._id}&show=NOREADED`;

    const notificationsPayload = await axios
      .get(url)
      .catch((err) => console.log(err));
    setIsLoading(false);
    if (notificationsPayload?.data) {
      console.log(
        "NOTIFICATIONS",
        notificationsPayload.data.notifications.length
      );
      setCount(notificationsPayload.data.notifications.length);
    }
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const text = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>Photole Repair</span>
    </div>
  );
  const content = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ marginRight: "15px" }}>
          <Avatar
            size={58}
            style={{ border: "3px solid #E16726" }}
            src={
              avatar !== null &&
              avatar !== undefined &&
              avatar !== "null" &&
              avatar !== "undefined"
                ? avatar
                : NoAvatar
            }
          ></Avatar>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            {fullName}
          </span>
          <span style={{ fontSize: "15px" }}>{email || ""}</span>
          <span style={{ fontSize: "15px", paddingTop: "10px" }}>
            <Button onClick={logoutUser} style={{ marginLeft: "5px" }}>
              <PoweroffOutlined style={{ paddingRight: "5px" }} />
              Logout
            </Button>
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div className="menu-top">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: "0.5rem",
        }}
      >
        <img
          className="menu-top__left-logo"
          src={Logo}
          alt="Logo"
          onClick={() => (window.location.href = `/`)}
          style={{
            width: !menuCollapsed ? "100%" : "100%",
            height: !menuCollapsed ? "80px" : "53px",
          }}
        ></img>

        <Button
          type="Link"
          onClick={() => setMenuCollapsed(!menuCollapsed)}
          style={{ marginLeft: "1rem" }}
        >
          {menuCollapsed ? <MenuOutlined /> : <MenuUnfoldOutlined />}
        </Button>
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingRight: "2rem",
        }}
      >
        <button
          type="Link"
          style={{ paddingRight: "2rem", cursor: "pointer" }}
          onClick={navigateToNotifications}
        >
          <Badge count={count}>
            <NotificationAddOutlinedIcon
              style={{ color: "#6A6A6A" }}
              onClick={navigateToNotifications}
            />
          </Badge>
        </button>
        <button type="Link" style={{ paddingRight: "2rem", cursor: "pointer" }}>
          <MarkunreadOutlinedIcon style={{ color: "#6A6A6A" }} />
        </button>
        <Popover
          placement="bottomRight"
          title={text}
          content={content}
          trigger="click"
        >
          <Avatar
            size="default"
            style={{ border: "3px solid #E16726", cursor: "pointer" }}
            src={
              avatar !== null &&
              avatar !== undefined &&
              avatar !== "null" &&
              avatar !== "undefined"
                ? avatar
                : NoAvatar
            }
          />
        </Popover>
      </div>
    </div>
  );
}
