import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

import HomeIcon from "@mui/icons-material/Home";
import { currencyFormatter } from "../../helpers/currencyHelper";

export function Indicator({ title, value, color }) {
  const drawIcon = () => {
    switch (title) {
      case "Sites":
        return <HomeIcon style={{ color: color }} />;
      case "Total Repairs":
        return <ArrowCircleUpIcon style={{ color: color }} />;
      case "Closed Jobs":
        return <EventAvailableIcon style={{ color: color }} />;
      case "Scheduled Jobs":
        return <CalendarMonthIcon style={{ color: color }} />;
      case "Open Invoices":
        return <ReceiptOutlinedIcon style={{ color: color }} />;
      default:
        return <AttachMoneyIcon style={{ color: color }} />;
    }
  };

  const convertMoney = (value) => {
    const money = currencyFormatter(value);
    return money;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          alignItems: "flex-end",
          borderRight: "1px solid #ccc",
          marginTop: "1rem",
          paddingRight: "2rem",
          paddingLeft: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontWeight: "bold", color: "#6A6A6A" }}>{title}</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{drawIcon()}</div>
            <span
              style={{ color: color, fontWeight: "bold", marginLeft: "5px" }}
            >
              {title === "Open Invoices" ? convertMoney(value) : value}
            </span>
          </div>
        </div>

        {/* <BarChartIcon
          style={{ fontSize: "50px", color: color, marginLeft: "10px" }}
        /> */}
      </div>
    </>
  );
}
