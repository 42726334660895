import React from "react";
import MaterialTable from "@material-table/core";
import { DownloadOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import moment from "moment";
import axios from "axios";
import { FILES_URL } from "../../../config/config";

import "./OpenDocumentList.css";
export function OpenDocumentList({ data, title }) {
  const columns = [
    {
      title: "Document Name",
      field: "documentName",
    },
    {
      title: "DocumentType",
      field: "documentType",
    },
    {
      title: "Upload Date",
      field: "uploadedDate",
      render: (rowData) => (
        <span>{moment(rowData?.uploadedDate).format("MM/DD/YYYY")}</span>
      ),
    },
  ];

  const onDownloadAction = (data) => {
    axios({
      url: `${FILES_URL}/${data.documentPath}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${data.documentName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const onViewAction = (data) => {
    const url = `${FILES_URL}/${data.documentPath}`;
    window.open(url, "_blank");
  };
  return (
    <div className="listContainer">
      <MaterialTable
        columns={columns}
        className="tableDocuments"
        data={data}
        title={title}
        actions={[
          {
            icon: DownloadOutlined,
            tooltip: "Download",
            onClick: (event, rowData) => onDownloadAction(rowData),
          },
          {
            icon: VisibilityIcon,
            tooltip: "View",
            onClick: (event, rowData) => onViewAction(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,

          search: false,
        }}
      />
    </div>
  );
}
